import { useLocation } from "react-router-dom";
import querystring from "query-string";
import { useState, useEffect } from "react";
import GeneralErrorContainer from "../Containers/GeneralErrorContainer/GeneralErrorContainer";

const isAlphabeticRegex = /^[A-Za-z]+$/;

function checkParamValidity(params) {
  let paramValid = true;
  let missingParams = [];
  let invalidParams = [];
  
  if (!params.schemaname) {
    missingParams.push("schemaname");
    paramValid = false;
    return { paramValid, missingParams, invalidParams };
  } else if (params.schemaname.length === 0) {
    invalidParams.push("schemaname");
    paramValid = false;
    return { paramValid, missingParams, invalidParams };
  }

  return { paramValid, missingParams, invalidParams };
}

export default function ParamGuard(props) {
  const location = useLocation();
  const [validationObject, setValidationObject] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location && location.search) {
      const parsedSearch = querystring.parse(location.search.toLowerCase());
      const validationPayload = checkParamValidity(parsedSearch);
      if (window && window.location && window.location.href) {
        setValidationObject({
          ...validationPayload,
          initialUrl: window.location.href,
        });
      } else {
        setValidationObject(validationPayload);
      }
    }
    setLoading(false);
  }, []);

  return validationObject && validationObject.paramValid ? (
    props.children
  ) : (
    <GeneralErrorContainer
      errorMessage="Parameters not found!"
      errorDescription="Looks like the necessary parameters were not passed to the URL or were not valid. Make sure to have the right navigation link when trying to access this page."
      validation={validationObject}
      loading={loading}
    />
  );
}
