import {CRITICAL_ACTIVE, NON_EFFECTIVE_ACTIVE, TOGGLE_DIAGRAM} from './filterTypes'

export const DIAGRAM_MODE = {
    BOWTIE: "BOWTIE",
    BUTTERFLY: "BUTTERFLY"
}


const defaultDiagramMode = localStorage.getItem("diagramMode");

const INITIAL_STATE = {
    diagramMode: defaultDiagramMode ? defaultDiagramMode : DIAGRAM_MODE.BOWTIE,
    criticalFilter: true,
    nonEffectiveFilter: true,
    criticalFilterValues: {
        truthy: "Critical",
        falsy: "Non Critical"
    },
    nonEffectiveFilterValues: {
        truthy: "Effective",
        falsy: "Not Effective"
    }
}


export default function filterReducer(state = INITIAL_STATE, {type, payload}) {

    if(type === CRITICAL_ACTIVE) {
        return {...state, criticalFilter: payload}
    }

    if(type === NON_EFFECTIVE_ACTIVE) {
        return {...state, nonEffectiveFilter: payload}
    }

    if(type === TOGGLE_DIAGRAM) {
        return {...state, diagramMode: payload}
    }

    return state;
}