import { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useUser } from "@myosh/myosh-login";
import ParamGuard from "../../guards/ParamGuard";

function PrivateRoute({ component: Component, ...rest }) {
  const location = useLocation();

  const { state } = useUser();

  useEffect(() => {
    if (!localStorage.getItem("Bowtie")) {
      localStorage.setItem("Bowtie", 0);
    }
  }, [state]);

  const isAuthenticated = state.auth;

  return (
    <Route
      {...rest}
      render={(props) => {
        const initialParams = location.search;

        if (
          initialParams &&
          initialParams.length > 0 &&
          initialParams.includes("schemaName") &&
          initialParams.includes("records")
        ) {
          localStorage.setItem("initialParams", initialParams);
        }

        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <ParamGuard>
            <Component {...props} />
          </ParamGuard>
        );
      }}
    />
  );
}

export default PrivateRoute;
