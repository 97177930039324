import './CirclePlus.css';
import PropTypes from 'prop-types';
import { palettes } from '../../../environment/environment';

export default function CirclePlus() {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='6'
        cy='6'
        r='5.75'
        fill='white'
        stroke={palettes.gray.primary}
        strokeWidth='0.5'
      />
      <line
        x1='9'
        y1='5.88806'
        x2='3'
        y2='5.88806'
        stroke={palettes.gray.primary}
        strokeWidth='0.5'
      />
      <line
        x1='5.78613'
        y1='3.15625'
        x2='5.78613'
        y2='9.15625'
        stroke={palettes.gray.primary}
        strokeWidth='0.5'
      />
    </svg>
  );
}
