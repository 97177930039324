import { AuthService } from "@myosh/myosh-login";
import axios from "axios";
import axiosRetry from "axios-retry";

const api = axios.create({});

const authService = new AuthService();

axiosRetry(api, {
    retries: 5,
    retryCondition: (error) => {
      if(error && !error.response) {
        return false;
      }

      if (error && error.response && error.response.status && error.response.status === 401) {
        let valid = true;
        authService
          .signinSilent()
          .then((userData) => {
            if(userData && userData.message) {
              authService.logout();
            }

            if (userData) {
              localStorage.setItem("idToken", userData.id_token);
              localStorage.setItem("refreshToken", userData.refresh_token);
              valid = true;
            } else {
              valid = false;
            }
          })
          .catch((err) => {
            authService.logout();
            valid = false;
          });

        return valid;
      } else {
        return false;
      }
    },
    retryDelay: (retryCount) => {
      return retryCount * 2000;
    }
});

class Request {
  static async doRequest(options) {
    api.interceptors.request.use((requestConfig) => {
      requestConfig.headers = {
        ...requestConfig.headers,
        Authorization: `Bearer ` + localStorage.getItem("idToken"),
        "myosh-schema": localStorage.getItem("schema")
      };

      const storedSchema = localStorage.getItem("schema");

      if(storedSchema) {
        requestConfig.headers["myosh-schema"] = storedSchema;
      }
  
      return requestConfig;
    });
    try {
      const response = await api(options);
      return { success: true, payload: response };
    } catch (error) {
      return { success: false, payload: error };
    }
  }
}

export default Request;