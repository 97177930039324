import React, { useState, useEffect } from "react";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import filterSelectors from "../../../modules/filter/filterSelectors";
import filterActions from "../../../modules/filter/filterActions";
import { DIAGRAM_MODE } from "../../../modules/filter/filterReducer";
import DropDown from "../../../views/draftContainer/shared/components/DropDown";
import diagramSelectors from "../../../modules/diagram/diagramSelectors";
import { useLocation, useHistory } from "react-router";
import userSelectors from "../../../modules/user/userSelectors";
import { palettes } from "../../../environment/environment";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const [settingAnchorEl, setSettingAnchorEl] = useState(null);
  const open = Boolean(settingAnchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  const dispatch = useDispatch();
  const criticalFilter = useSelector(filterSelectors.selectCriticalFilter);
  const nonEffectiveFilter = useSelector(
    filterSelectors.selectNonEffectiveFilter
  );
  const diagramMode = useSelector(filterSelectors.selectDiagramMode);
  const userWithPermissions = useSelector(userSelectors.selectUser);

  const toggleFilter = (e) => {
    const id = e.target.id;

    if (e.target.classList.contains(`${id}-active`)) {
      e.target.classList.remove(`${id}-active`);
    } else {
      e.target.classList.add(`${id}-active`);
    }
  };

  const handleOpenSetting = (event) => {
    setSettingAnchorEl(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setSettingAnchorEl(null);
  };

  const toggleDiagramMode = (selectedDiagramMode) => {
    handleCloseSetting();
    dispatch(filterActions.toggleDiagramMode(selectedDiagramMode));
  };

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const AddNew = () => {
    const configuration = JSON.parse(localStorage.getItem("bowtieConfiguration"));
    const { id, moduleId } = configuration?.forms?.main;

    setTimeout(() => {
      focusRiskScenarioContainer();
    });

    if (queryParams.has("records")) {
      queryParams.delete("records"); 
    }

    queryParams.set('formId', id)
    queryParams.set('moduleId', moduleId)

    window.open(window.location.origin + "?" + queryParams.toString());
  };

  const focusRiskScenarioContainer = () => {
    const riskScenarioContainer = document.getElementById("risk-scenario");
    if (riskScenarioContainer) {
      riskScenarioContainer.focus();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      focusRiskScenarioContainer();
    });
  }, []);

  return (
    <React.Fragment>
      <div className="header-container" id="header">
        <div className="first-row-container">
        <div
          className="first-row"
          style={{
            display: "flex",
            alignContent: "center",
            gap: "12px",
            zIndex: 3000
          }}
        >
          <DropDown />
          {userWithPermissions && userWithPermissions.permissions && userWithPermissions.permissions.createRecord && userWithPermissions.permissions.createRecord.main && <button
            style={{
              color: "white",
              padding: "11px 38px",
              backgroundColor: palettes.darkBlue.primary,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              textOverflow: "ellipsis",
              width: "37%",
            }}
            className="button-radius"
            onClick={AddNew}
          >
            + Add new
          </button>}
          <button
              onClick={() => history.go(0)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "20px",
                color: "#A4B0C7",
              }}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "5px" }}
              >
                <path
                  d="M4.50168 4.22403e-06C3.6606 -0.00114855 2.83608 0.233671 2.12181 0.677772C1.40754 1.12187 0.83218 1.75745 0.461127 2.51225C0.0900739 3.26705 -0.061791 4.1108 0.0227934 4.94761C0.107378 5.78442 0.425019 6.58072 0.939613 7.246C1.45421 7.91128 2.14512 8.41886 2.93381 8.71105C3.7225 9.00323 4.57734 9.0683 5.40117 8.89886C6.225 8.72942 6.98477 8.33226 7.59414 7.75254C8.2035 7.17281 8.63801 6.43376 8.84828 5.6194H7.67888C7.47282 6.20224 7.11047 6.71721 6.63147 7.108C6.15246 7.4988 5.57524 7.75038 4.96289 7.83524C4.35054 7.92009 3.72666 7.83497 3.15943 7.58916C2.5922 7.34335 2.10348 6.94632 1.74668 6.44148C1.38989 5.93664 1.17876 5.34342 1.13637 4.72668C1.09399 4.10993 1.22198 3.49341 1.50637 2.94451C1.79075 2.3956 2.22057 1.93545 2.74883 1.61436C3.2771 1.29326 3.88348 1.12359 4.50168 1.12388C4.94385 1.12453 5.38147 1.21332 5.78893 1.38507C6.19639 1.55681 6.56552 1.80806 6.87475 2.12413L5.06643 3.93358H9V4.22403e-06L7.67888 1.32056C7.26259 0.901737 6.76754 0.56943 6.22224 0.342787C5.67695 0.116144 5.0922 -0.000354558 4.50168 4.22403e-06Z"
                  fill="#A4B0C7"
                />
              </svg>
            </button>   
        </div> 
        <div className="diagram-toggle-container" style={{ display: "flex" }}>
          <div className="filter-button-container">
            <div className="legend">
              <div className="legend-container">
                <div id="critical" className={`legend-box filter-button control`} />
                <label>Control</label>
              </div>
              <div className="legend-container">
                <div id="critical" className={`legend-box filter-button ${criticalFilter ? 'critical-active' : ''}`} />
                <label>Critical Control</label>
              </div>
              <div className="legend-container">
                <div id="non-critical" className={`legend-box filter-button ${nonEffectiveFilter ? 'non-critical-active' : ''}`} />
                <label>Control Issues</label>
              </div>
            </div>
          </div>
            <div title="Switch layout"  className="setting">
              <FontAwesomeIcon 
                className="setting-icon" 
                icon={faGear} 
                color={palettes.darkBlue.primary} 
                onClick={handleOpenSetting}
              />
            </div>
          </div>
        </div>
      
        <div className="second-row-container">    
        </div>
        <Menu
            id={id}
            open={open}
            anchorEl={settingAnchorEl}
            onClose={handleCloseSetting} 
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box display='flex' flexDirection='column'>
              <MenuItem 
                title="Switch to bowtie layout"
                className={diagramMode === DIAGRAM_MODE.BOWTIE ? 
                  "setting-selected-color" : ''} 
                onClick={() => toggleDiagramMode(DIAGRAM_MODE.BOWTIE)}>
                  Bowtie
              </MenuItem> 
              <MenuItem 
                title="Switch to butterfly layout"
                className={diagramMode === DIAGRAM_MODE.BUTTERFLY ?
                "setting-selected-color" : ''} 
                onClick={() => toggleDiagramMode(DIAGRAM_MODE.BUTTERFLY)}>
                  Butterfly
              </MenuItem>
            </Box>
          </Menu>
      </div>
    </React.Fragment>
  );
}
