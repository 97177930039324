import {USER_FETCH_LOADING, USER_FETCH_FAILURE, USER_FETCH_SUCCESS} from './userTypes';

const INITIAL_STATE = {
    user: null,
    userLoading: true,
    userError: null
}


export default function(state = INITIAL_STATE, {type, payload}) {


    if(type === USER_FETCH_LOADING) {
        return {
            ...state,
            userLoading: true,
            userError: null
        }
    }

    if(type === USER_FETCH_FAILURE) {
        return {
            ...state,
            userError: payload,
            userLoading: false
        }
    }


    if(type === USER_FETCH_SUCCESS) {
        return {
            ...state,
            user: payload,
            userError: null,
            userLoading: false
        }
    }

    return state;
}