import React, { useEffect, useState, useRef } from "react";
import Header from "../../Components/layout/header/Header";
import Diagram from "../../Components/diagram/Diagram";
import diagramActions from "../../modules/diagram/diagramActions";
import diagramSelectors from "../../modules/diagram/diagramSelectors";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../Components/Items/LoadingComponent/LoadingComponent";
import GeneralErrorContainer from "../../Components/Containers/GeneralErrorContainer/GeneralErrorContainer";
import { Helmet } from "react-helmet";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LineSkeleton from "../../Components/diagram/shared/lineSkeleton/LineSkeleton";
import filterSelectors from "../../modules/filter/filterSelectors";
import userActions from "../../modules/user/userActions";
import { useUser } from "@myosh/myosh-login";
import userSelectors from "../../modules/user/userSelectors";
import "./Main.css";
import { palettes } from "../../environment/environment";
import RecordService from "../../modules/diagram/diagramService";
import { riskScenarioPageSize } from "../../environment/environment";
import { 
  DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
  DIAGRAM_FETCH_RECORD_FAIL,
  DIAGRAM_CONFIGURATION_MAIN_SUCCESS,
  DIAGRAM_CONFIGURATION_MAIN_FAIL
} from "../../modules/diagram/diagramTypes";

const recordService = new RecordService("test");

export default function Main() {
  const [appTitle, setAppTitle] = useState(null);
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const diagramMode = useSelector(filterSelectors.selectDiagramMode);
  const [diagramScale, setDiagramScale] = useState(null);
  const [transformComponentMarginTop, setTransformComponentMarginTop] =
    useState(null);
  const [transformComponentMarginLeft, setTransformComponentMarginLeft] =
    useState(null);

  const diagramRef = useRef();
  const digramWrapperRef = useRef();
  const diagramDataRef = useRef();

  const userLoading = useSelector(userSelectors.selectUserLoading);
  const queue = useSelector(diagramSelectors.selectQueues);

  const authUserState = useUser();
  const dispatch = useDispatch();
  const location = useLocation();

  const loading = useSelector(diagramSelectors.selectLoading);
  const errorMessage = useSelector(diagramSelectors.selectErrorMessage);
  const bowtieData = useSelector(diagramSelectors.selectBowtieData);
  const records = useSelector(diagramSelectors.selectScenarioRecords);
  const diagramLayout = useSelector(diagramSelectors.selectDiagramLayout);
  const diagramContrainer = document.getElementById("diagram");
  const storedBowtieConfiguration = localStorage.getItem(
    "bowtieConfiguration"
  );

  useEffect(() => {
    if (bowtieData && bowtieData.scenario) {
      setAppTitle(bowtieData.scenario);
    } else {
      setAppTitle(null);
    }
  }, [bowtieData]);
 
  useEffect(() => {
    if (queue?.tasks?.length > 0) {
      if (!queue.isQueuing) {
        dispatch(diagramActions.doUpdateQueueStatus(true))
        const { payload, type } = queue.tasks[0];

        if (type === 'preventative_control') {
          dispatch(diagramActions.doAddPreventativeControls(payload));
        } else {
          dispatch(diagramActions.doAddMitigatingControls(payload));
        }
      }
    }
  }, [dispatch, queue?.tasks, queue?.isQueuing])

  useEffect(() => {
    if (queue?.failed?.length > 0) {
      if (!queue.isRemovingQueue) {
        dispatch(diagramActions.doUpdateFailedQueueStatus(true))
        const { payload, type } = queue.failed[0];
        const { id, parentId } = payload;
        const data = {
          id,
          parentId
        };

        if (type === 'preventative_control') {
          dispatch(diagramActions.doRemovePreventativeControl(data));
        } else {
          dispatch(diagramActions.doRemoveMitigatingControl(data));
        }
      }
    }
  }, [dispatch, queue?.failed, queue?.isRemovingQueue])

  useEffect(() => {
    if (!!storedBowtieConfiguration && !!authUserState?.state?.user && dispatch) {
      dispatch(
        userActions.doFetchAuthenticatedUserAndSetPermissions(
          authUserState.state.user,
          JSON.parse(storedBowtieConfiguration).forms
        )
      );
    }
  }, [authUserState?.state?.user, dispatch, storedBowtieConfiguration]);
  
  useEffect(() => {
    if (diagramRef && diagramRef.current) {
        setDiagramScale((window.innerWidth / diagramRef.current.clientWidth) - 0.06);
    }
  }, [diagramRef, diagramRef.current]);

  useEffect(() => {
    if (location && location.search) {
      const parsedQuery = querystring.parse(location.search);

      if (parsedQuery && parsedQuery.records) {
        dispatch(diagramActions.doFetchAndTransformRecord(parsedQuery.records));
      } else if (parsedQuery.formId && parsedQuery.moduleId) {
          recordService.doFetchScenarioRecords({
            formId: parsedQuery?.formId,
            isNewRiskScenario: false
          }).then(async (response) => {
            const storedBowtieConfiguration = JSON.parse(localStorage.getItem(
              "bowtieConfiguration"
            )); 
            const mainPayloadConfiguration = await recordService.doGetMainFormPayload({
              moduleId: parsedQuery?.moduleId,
              id: parsedQuery?.formId
            })
            dispatch({
              type: DIAGRAM_CONFIGURATION_MAIN_SUCCESS,
              payload: mainPayloadConfiguration
            });
            const { result } = mainPayloadConfiguration?.payload?.data
            const transformRecords = await recordService.doTransformScenarioRecords(
              response,
              storedBowtieConfiguration,
              result
            );
            const scenarioRecords = [
              {
                value: 0,
                label: 'New Diagram',
                selected: true,
                itemColor: '#fff'
              },
              ...transformRecords
            ];
  
            dispatch({
              type: DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
              payload: {
                records: scenarioRecords,
                isLastPage: (records?.length < riskScenarioPageSize)
              },
            });
          }).catch((err) => {
            dispatch({
              type: DIAGRAM_FETCH_RECORD_FAIL,
              payload: err?.message,
            });
            dispatch({
              type: DIAGRAM_CONFIGURATION_MAIN_FAIL,
            });
          });
      } else {
         dispatch({
            type: DIAGRAM_FETCH_RECORD_FAIL,
            payload: 'Parameter error',
          });
      }
    }
  }, [dispatch, location, records?.length]);

  useEffect(() => {
    resetZoomPercentage();
  }, [])

  const resetZoomPercentage = () => {
    setZoomPercentage(100);
  };

  const increaseZoomPercentage = () => {
    setZoomPercentage((oldZoomPercentage) => {
      if(oldZoomPercentage < 200) {
        return oldZoomPercentage + 20;
      }

      return oldZoomPercentage;
    });
  };

  const decreaseZoomPercentage = () => {
    setZoomPercentage((oldZoomPercentage) => {
      if(oldZoomPercentage > 20) {
        return oldZoomPercentage - 20;
      }

      return oldZoomPercentage;
    });
  };

  const [diagramDimensions, setDiagramDimensions] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      const diagram = document.getElementById("diagram");

      if (diagram) {
        setDiagramDimensions(diagram.getBoundingClientRect());
      }
    });
  }, []);

  const setDiagramMargin = (state) => {
    setTransformComponentMarginTop(
      state.positionY < 0 ? 0 - state.positionY : 0
    );
    setTransformComponentMarginLeft(
      state.positionX < 0 ? 0 - state.positionX : 0
    );
  };

  const setScrollDiagramLayout = () => {
    const mainAreaContainer = document.querySelector(
      ".diagram-transform-container"
    );

    mainAreaContainer.scrollTo({
      left: 0,
      top: 0, 
    });
  }

  const ComponentScale = () => {
    return (
      <div 
        className="diagram-zoom-controls" 
        id="diagram-zoom-controls"
      >
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="zoom-container">
            <div className="zoom-percentage-container">
              <p>{zoomPercentage}%</p>
            </div>
            <div className='zoom-icon'>
              <svg
                width="20"
                height="20"
                viewBox="0 0 12 12"
                fill="none"
              >
                <circle
                  cx="6"
                  cy="6"
                  r="5.75"
                  fill="white"
                  stroke="#A4B0C7"
                  strokeWidth="0.5"
                />
                <line
                  x1="9"
                  y1="5.88806"
                  x2="3"
                  y2="5.88806"
                  stroke="#A4B0C7"
                  strokeWidth="0.5"
                />
                <line
                  x1="5.78613"
                  y1="3.15625"
                  x2="5.78613"
                  y2="9.15625"
                  stroke="#A4B0C7"
                  strokeWidth="0.5"
                />
              </svg>
            </div>
            <div className='zoom-icon'>
              <svg
                width="20"
                height="20"
                viewBox="0 0 12 12"
                fill="none"
              >
                <circle
                  cx="6"
                  cy="6"
                  r="5.75"
                  fill="white"
                  stroke="#A4B0C7"
                  strokeWidth="0.5"
                />
                <line
                  x1="9"
                  y1="5.88806"
                  x2="3"
                  y2="5.88806"
                  stroke="#A4B0C7"
                  strokeWidth="0.5"
                />
              </svg>
            </div>
            <div className='zoom-icon'>
              <svg
                width="20"
                height="20"
                viewBox="0 0 12 12"
                fill="none"
              >
                <circle
                  cx="6"
                  cy="6"
                  r="5.75"
                  fill="white"
                  stroke="#A4B0C7"
                  strokeWidth="0.5"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (
        diagramLayout?.isWidth  && 
        zoomPercentage === 100 && 
        digramWrapperRef?.current && 
        diagramDataRef?.current && 
        window?.innerWidth
      ) {
      const transformScale = 
      (window.innerWidth / diagramDataRef.current.clientWidth) - 0.06;

      setDiagramScale(transformScale);
      digramWrapperRef.current.state.scale = transformScale;
      digramWrapperRef.current.setTransform(transformScale);
      digramWrapperRef.current.centerView();
      setDiagramMargin(digramWrapperRef.current.state);
      dispatch(diagramActions.doUpdateDiagramLayout({
        isWidth: false,
      }));
    }
  }, [
    dispatch, 
    zoomPercentage, 
    diagramLayout?.isWidth,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {appTitle && appTitle.length > 0
            ? `${appTitle} | Bowtie by myosh`
            : "New Diagram | Bowtie by myosh"}
        </title>
      </Helmet>
      <Header />
      {errorMessage && errorMessage.length > 0 ? (
        <GeneralErrorContainer
          errorMessage={"An error occured"}
          errorDescription={errorMessage}
        />
      ) : (
        <>
          {loading && querystring.parse(location.search).records ? 
            <>
              <ComponentScale />
              <div className="scale">
                <Diagram isLoading />
              </div>
            </>
          : 
          diagramScale ? (
            <TransformWrapper
              ref={digramWrapperRef}
              initialScale={diagramScale}
              wheel={{ wheelDisabled: true, disabled: true }}
              minScale={0.1}
              maxScale={6}
              centerOnInit={true}
              doubleClick={{
                disabled: true
              }}
              panning={{
                disabled: true,
                excluded: [
                  "textarea",
                  "svg",
                  "main-area-container",
                  "hazard-container",
                ],
              }}
              onZoomStop={(event) => {
                setDiagramMargin(event.state);
              }}
            >
              {({ zoomIn, zoomOut, resetTransform, state, ...rest }) => {
                setDiagramMargin(state);

                return (
                  <React.Fragment>
                    <div
                      style={{
                        width: "100%",
                        height: "50px",
                        top: "0px",
                        right: "0px",
                        zIndex: 4000,
                        display: "flex",
                        justifyContent: "row-reverse",
                        padding: "0rem 2.7rem",
                        marginTop: "-0.7rem",
                      }}
                      id="diagram-zoom-controls"
                    >
                      <div
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0px 47px",
                            color: "white",
                            width: "100%",
                            height: "100%",
                            gap: "5px",
                          }}
                        >
                          <div className="zoom-percentage-container">
                            <p>{zoomPercentage}%</p>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#A4B0C7",
                              padding: "3px",
                            }}
                            className='zoom-icon'
                            onClick={() => {
                              increaseZoomPercentage();
                              zoomIn();
                              setTimeout(() => {
                                setDiagramMargin(state);
                              }, 300);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <circle
                                cx="6"
                                cy="6"
                                r="5.75"
                                fill="white"
                                stroke="#A4B0C7"
                                strokeWidth="0.5"
                              />
                              <line
                                x1="9"
                                y1="5.88806"
                                x2="3"
                                y2="5.88806"
                                stroke="#A4B0C7"
                                strokeWidth="0.5"
                              />
                              <line
                                x1="5.78613"
                                y1="3.15625"
                                x2="5.78613"
                                y2="9.15625"
                                stroke="#A4B0C7"
                                strokeWidth="0.5"
                              />
                            </svg>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#A4B0C7",
                              padding: "3px",
                            }}
                            className='zoom-icon'
                            onClick={() => {
                              zoomOut();
                              decreaseZoomPercentage();
                              setTimeout(() => {
                                setDiagramMargin(state);
                              }, 300);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <circle
                                cx="6"
                                cy="6"
                                r="5.75"
                                fill="white"
                                stroke="#A4B0C7"
                                strokeWidth="0.5"
                              />
                              <line
                                x1="9"
                                y1="5.88806"
                                x2="3"
                                y2="5.88806"
                                stroke="#A4B0C7"
                                strokeWidth="0.5"
                              />
                            </svg>
                          </div>
                          <div
                            style={{
                              backgroundColor: "#A4B0C7",
                              padding: "3px",
                            }}
                            className='zoom-icon'
                            onClick={() => {
                              setScrollDiagramLayout();
                              setTimeout(() => {
                                setTransformComponentMarginLeft(null);
                                setTransformComponentMarginTop(null);
                                setDiagramMargin(state);
                                resetTransform();
                                resetZoomPercentage();
                                dispatch(diagramActions.doUpdateDiagramLayout({
                                  isWidth: false,
                                }));
                              }, [100])
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <circle
                                cx="6"
                                cy="6"
                                r="5.75"
                                fill="white"
                                stroke="#A4B0C7"
                                strokeWidth="0.5"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <TransformComponent
                      wrapperStyle={{
                        overflowX: zoomPercentage <= 100 ? 'hidden': 'scroll',
                        overflowY: 'scroll',
                        zIndex: 1000,
                        height: "80%",
                        width: "100%",
                        position: "relative",
                      }}
                      wrapperClass="diagram-transform-container"
                      contentStyle={{
                        marginTop: transformComponentMarginTop && transformComponentMarginTop + "px",
                        marginLeft: transformComponentMarginLeft && transformComponentMarginLeft + "px"
                      }}
                    >
                      {bowtieData && bowtieData.scenario && (
                        <LineSkeleton
                          bowtieData={{
                            causes: bowtieData.causes,
                            consequences: bowtieData.consequences,
                          }}
                          getDiagramSizeAndPosition={0.3}
                          strokeColor={palettes.criticalControl.primary}
                          diagramMode={diagramMode}
                        />
                      )}
                      <div ref={diagramDataRef}>
                        <Diagram isLoading={false} />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                );
              }}
            </TransformWrapper>
          ) : (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                visibility: "hidden"
              }}
            >
              <Diagram isLoading diagramRef={diagramRef} />
            </div>
          )}
        </>
      )}
    </>
  );
}
