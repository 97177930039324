import "./Diagram.css";
import { useDispatch } from "react-redux";
import LineSkeleton from "./shared/lineSkeleton/LineSkeleton";
import GeneralContainer from "./shared/generalContainer/GeneralContainer";
import MultiLineContainer from "./shared/multiLineContainer/MultiLineContainer";
import Rectangular from "./shared/rectangular/Rectangular";
import recordSelectors from "../../modules/diagram/diagramSelectors";
import { useSelector } from "react-redux";
import React, { useEffect, useMemo, useState, useRef } from "react";
import diagramActions from "../../modules/diagram/diagramActions";
import filterSelectors from "../../modules/filter/filterSelectors";
import { DIAGRAM_MODE } from "../../modules/filter/filterReducer";
import Controls from "../../views/draftContainer/shared/components/Controlls";
import { useLocation } from "react-router-dom";
import querystring from "query-string";
import RecordService from "../../modules/diagram/diagramService";
import { add } from "lodash";
import userSelectors from "../../modules/user/userSelectors";
import { palettes, hazardAndRiskDefaultStyle } from "../../environment/environment";
import LoadingControls from "../../Components/Items/LoadingComponent/LoadingControls";

const recordService = new RecordService("test");

export default function Diagram(props) {
  const {diagramRef, isLoading} = props;

  const location = useLocation();
  const dispatch = useDispatch();
  const bowtieData = useSelector(recordSelectors.selectBowtieData);
  const diagramMode = useSelector(filterSelectors.selectDiagramMode);
  const [riskScenarioInputValue, setRiskScenarioInputValue] = useState(null);
  const userWithPermissions = useSelector(userSelectors.selectUser);
  const [diagramDimensions, setDiagramDimensions] = useState(null);
  const diagramContrainer = document.getElementById("diagram");
  const [editRiskScenario, setEditRiskScenario] = useState(false);
  const [editHazard, setEditHazard] = useState(false);
  const isRiskScenarioLoading = useSelector(recordSelectors.selectAddLoading);
  const [isEditRiskScenarioDisabled, setIsEditRiskScenarioDisabled] = useState(false);
  const [isEditHazardDisabled, setIsEditHazardDisabled] = useState(false);
  const [showHazard, setShowHazard] = useState(true);

  const addCauseInput = () => {
    dispatch(diagramActions.doAddCauseInput());
  };

  const addConsequenceInput = () => {
    dispatch(diagramActions.doAddConsequenceInput());
  };

  let myTransform = null;
  useEffect(() => {
    setTimeout(() => {
      const causesContainer = document.getElementById("causes-container");
      const consequencesContainer = document.getElementById(
        "consequences-container"
      );
      const mitigatingControlsContainer = document.getElementById(
        "mitigating-controls-container"
      );
      const preventativeControlsContainer = document.getElementById(
        "preventative-controls-container"
      );
      const mainAreaContainer = document.getElementById("main-area-container");

      const diagramPieces = [
        causesContainer,
        consequencesContainer,
        mitigatingControlsContainer,
        preventativeControlsContainer,
        mainAreaContainer,
      ];

      let width = 0;
      let height = 0;

      diagramPieces.forEach((diagramPiece) => {
        if (diagramPiece) {
          const diagramPieceDimensions = diagramPiece.getBoundingClientRect();
          width += diagramPieceDimensions.width;
          height += diagramPieceDimensions.height;
        }
      });

      setDiagramDimensions({
        width,
        height,
      });
    });
    if (myTransform) {
      myTransform(0);
    }
  }, [diagramMode]);

  const getDiagramSizeAndPosition = useMemo(() => {
    if (
      diagramContrainer &&
      window.innerWidth < diagramContrainer.offsetWidth
    ) {
      const scale =
        (window.innerWidth - 88 * 2) / diagramContrainer.offsetWidth;

      return {
        scale,
        xPosition:
          (window.innerWidth - diagramContrainer.offsetWidth * scale) / 2,
        yPosition:
          (window.innerHeight - diagramContrainer.offsetHeight * scale) / 2,
      };
    }

    if (
      diagramContrainer &&
      window.innerWidth > diagramContrainer.offsetWidth
    ) {
      return {
        scale: 1,
        xPosition: 0,
        yPosition: 0,
      };
    }

    return { scale: 1, xPosition: 0, yPosition: 0 };
  }, [diagramContrainer]);

  const preventativeControlsLines = bowtieData.causes
    ? bowtieData.causes.map((cause) => {
        return {
          childControls: cause.preventativeControls,
          parentId: cause.uuid,
        };
      })
    : [];
  const mitigatingControlsLines = bowtieData.consequences
    ? bowtieData.consequences.map((consequence) => {
        return {
          childControls: consequence.mitigatingControls,
          parentId: consequence.uuid,
        };
      })
    : [];

  const queryParams = useMemo(() => 
    new URLSearchParams(location.search), 
    [location.search]
  );
  const addInput = useRef();
  const addHazard = useRef();

  useEffect(() => {
    if (addInput?.current?.focus && editRiskScenario) {
      addInput.current.focus();
    }

    if (addHazard?.current?.focus && editHazard) {
      addHazard.current.focus();
    }

  }, [addInput, addHazard, editRiskScenario, editHazard])

  useEffect(() => {  
    if (!queryParams?.get('records')) {
      setShowHazard(false);
    } else {
      setShowHazard(true);
    }
  }, [])

  const [riskScenarioTextAreaStyle, setRiskScenarioTextAreaStyle] = useState({
    ...hazardAndRiskDefaultStyle
  });

  const [hazardTextAreaStyle, setHazardTextAreaStyle] = useState({
    ...hazardAndRiskDefaultStyle
  });

  const handleOnRiskScenarioKeyDown = async (event) => {
    if ((event.code === "Enter" || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      if (addInput?.current?.value === '') {
        addInput.current.focus();
      } else {
        addInput.current.blur();
      }
    }
  }

  const handleUpdateRiskScenario = async () => {
    if (addInput?.current?.value !== "") {
      setIsEditRiskScenarioDisabled(true);

      if (addInput?.current?.value !== bowtieData.scenario) {
        const value = addInput.current.value;
        
        setRiskScenarioTextAreaStyle((oldStyle) => {
          oldStyle.borderStyle = "solid";
          oldStyle.color = "rgba(51, 51, 51, 0.5)";
          oldStyle.borderColor = palettes.criticalControl.rgb[5];

          return oldStyle;
        });

        await recordService.updateRiskScenarioField(
          bowtieData,
          value
        );
        
        setRiskScenarioInputValue(value);
        setEditRiskScenario(false);
        setIsEditRiskScenarioDisabled(false);
        dispatch(
          diagramActions.doUpdateRiskScenarioValue(value)
        );

        if (addHazard?.current?.value === "") {
          addHazard?.current?.focus();
        }
      } else {
        setEditRiskScenario(false);
        setIsEditRiskScenarioDisabled(false);
        setRiskScenarioTextAreaStyle((oldStyle) => {
          oldStyle.color = "#333";
          oldStyle.borderStyle = "dashed";
          oldStyle.borderColor = palettes.criticalControl.primary;
        
          return oldStyle;
        });
      }
    } else {
      setEditRiskScenario(false);
      setIsEditRiskScenarioDisabled(false);
    }
  }

  const handleClickEditRiskScenario = () => {
    setRiskScenarioTextAreaStyle((oldStyle) => {
      oldStyle.color = "#333";
      oldStyle.borderStyle = "dashed";
      oldStyle.borderColor = palettes.criticalControl.primary;
    
      return oldStyle;
    });
    if (userWithPermissions?.permissions?.editRecord?.main === true) {
      setEditRiskScenario(true);
    }
  }

  const handleOnHazardKeyDown = async (event) => {
    if ((event.code === "Enter" || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      if (addHazard?.current?.value === '') {
        addHazard.current.focus();
      } else {
        addHazard.current.blur();
      }
    }
  }

  const handleAddHazard = () => {
    if (addHazard?.current?.value !== '') {
      setIsEditHazardDisabled(true);
      setHazardTextAreaStyle((oldStyle) => {
        oldStyle.borderStyle = "solid";
        oldStyle.color = "rgba(51, 51, 51, 0.5)";
        oldStyle.borderColor = palettes.criticalControl.rgb[5];
  
        return oldStyle;
      });
      dispatch(
        diagramActions.doAddHazard({
          payload: addHazard.current.value, 
          onHazardDisabled: setIsEditHazardDisabled,
          onEditHazard: setEditHazard,
          onHazardTextAreaStyle: setHazardTextAreaStyle
        })
      );
    }
  }

  const handleUpdateHazard = async () => {
    if (addHazard?.current?.value !== '') {
      setIsEditHazardDisabled(true);
      if (addHazard?.current?.value !== bowtieData.hazard) {
        setHazardTextAreaStyle((oldStyle) => {
          oldStyle.borderStyle = "solid";
          oldStyle.color = "rgba(51, 51, 51, 0.5)";
          oldStyle.borderColor = palettes.criticalControl.rgb[5];

          return oldStyle;
        });
        dispatch(
          diagramActions.doAddHazard({
            payload: addHazard.current.value, 
            onHazardDisabled: setIsEditHazardDisabled,
            onEditHazard: setEditHazard,
            onHazardTextAreaStyle: setHazardTextAreaStyle
          })
        ); 
      } else {
        setEditHazard(false);
        setIsEditHazardDisabled(false);
        setHazardTextAreaStyle((oldStyle) => {
          oldStyle.color = "#333";
          oldStyle.borderStyle = "dashed";
          oldStyle.borderColor = palettes.criticalControl.primary;
        
          return oldStyle;
        });
      }
    } else {
      setEditHazard(false);
      setIsEditHazardDisabled(false);
    }
  }

  const loadingPreview = (message, className = "") => {
    return (
      <div className={className}>
        <LoadingControls className="loading-size" />
        {message}
      </div>
    )
  }

  const handleAddRiskScenario = () => {
    queryParams.delete('formId');
    queryParams.delete('moduleId');
    
    const urlParsed = queryParams.toString();

    if (addInput.current && addInput.current.value) {
      setRiskScenarioTextAreaStyle((oldStyle) => {
        oldStyle.borderStyle = "solid";
        oldStyle.color = "rgba(51, 51, 51, 0.5)";
        oldStyle.borderColor = palettes.criticalControl.rgb[5];
  
        return oldStyle;
      });
      dispatch(
        diagramActions.doAddRiskScenario({
          payload: addInput.current.value,
          urlParsed,
          addHazard
        })
      );
      setShowHazard(true);
    }
  }

  const diagramRender = () => {
    if (!bowtieData.scenario) {
      if (diagramMode === DIAGRAM_MODE.BOWTIE) {
        return (
          <React.Fragment>
            <Controls
              title={"Causes"}
              id="causes-container"
              content={
                isLoading ? loadingPreview('Causes will be displayed here') :
                userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.causes
                  ? "Add causes in this box"
                  : "Causes will be displayed here"
              }
              add={
                isLoading ? null :
                userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.causes
              }
              height={"75vh"}
              width={"10vw"}
              color={palettes.criticalControl.primary}
              onAdd={addCauseInput}
            />
            <Controls
              title={"Preventative Controls"}
              id="preventative-controls-container"
              content={
                isLoading ? loadingPreview('Preventative controls will be displayed here') :
                "Preventative controls will be displayed here"}
              height={"75vh"}
              width={"20vw"}
              color={palettes.gray.primary}
            />
            <div
              className="main-area-container"
              id="main-area-container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                position: "relative",
                padding: "0rem 8rem",
              }}
            >
              <div
                className="hazard-container"
                style={{
                  position: "absolute",
                  top: "0",
                }}
              >
                {bowtieData.hazard ? (
                  <Rectangular
                    text={bowtieData.hazard}
                    backgroundColor={palettes.gray.primary}
                    color={bowtieData.hazard ? "#ffffff" : "#333333"}
                    borderColor={palettes.gray.primary}
                    borderWidth="2px"
                    width="150px"
                    fontSize="20px"
                    id="hazard"
                    click={() => {
                      if (
                        userWithPermissions &&
                        userWithPermissions.permissions &&
                        userWithPermissions.permissions.editRecord &&
                        userWithPermissions.permissions.editRecord.main
                      ) {
                        setEditHazard(true);
                      }
                    }}
                  />
                ) : !showHazard ? null : (
                  isLoading ? <div style={{
                    ...hazardTextAreaStyle,
                    marginBottom: "8rem",
                    resize: "none",
                    flexDirection: 'column'
                  }}>
                    {loadingPreview('Hazard will be displayed here', 'hazard-risk')}
                  </div> :
                  <textarea
                    rows={2}
                    id="hazard"
                    ref={addHazard}
                    disabled={isEditHazardDisabled}
                    onKeyDown={handleOnHazardKeyDown}
                    placeholder="Click to add Hazard"
                    onBlur={handleAddHazard}
                    style={{
                      ...hazardTextAreaStyle,
                      marginBottom: "8rem",
                      resize: "none",
                      textOpacity: "20%",
                    }}
                  />
                )}
              </div>

              <div className="risk-scenario-container">
                {isLoading ? <div style={{
                    ...riskScenarioTextAreaStyle,
                    marginBottom: "8rem",
                    resize: "none",
                    flexDirection: 'column'
                  }}>
                    {loadingPreview('Risk will be displayed here', 'hazard-risk')}
                  </div> :
                <textarea
                  rows={2}
                  id="risk-scenario"
                  ref={addInput}
                  disabled={isRiskScenarioLoading}
                  onKeyDown={handleOnRiskScenarioKeyDown}
                  placeholder="Click to add Risk Scenario"
                  onBlur={handleAddRiskScenario}
                  style={{
                    ...riskScenarioTextAreaStyle,
                    marginBottom: "8rem",
                    resize: "none",
                    textOpacity: "20%",
                    width: "200px",
                  }}
                />}
              </div>
            </div>
            <Controls
              title={"Mitigating Controls"}
              id="mitigating-controls-container"
              content={
                isLoading ? loadingPreview('Mitigating controls will be displayed here') :
                "Mitigating controls will be displayed here"}
              height={"75vh"}
              width={"20vw"}
              color={palettes.gray.primary}
            />
            <Controls
              title={"Consequences"}
              id="consequences-container"
              content={
                isLoading ? loadingPreview('Consequences will be displayed here') :
                userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.consequences
                  ? "Add consequences in this box"
                  : "Consequences will be displayed here"
              }
              add={
                isLoading ? null :
                userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.consequences
              }
              height={"75vh"}
              width={"10vw"}
              color={palettes.criticalControl.primary}
              onAdd={addConsequenceInput}
            />
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <Controls
            title={"Preventative Controls"}
            id="preventative-controls-container"
            content={
              isLoading ? loadingPreview('Preventative controls will be displayed here') :
              "Preventative controls will be displayed here"}
            height={"75vh"}
            width={"20vw"}
            color={palettes.gray.primary}
          />

          <Controls
            title={"Causes"}
            id="causes-container"
            content={
              isLoading ? loadingPreview('Causes will be displayed here') :
              userWithPermissions &&
              userWithPermissions.permissions &&
              userWithPermissions.permissions.createRecord &&
              userWithPermissions.permissions.createRecord.causes
                ? "Add causes in this box"
                : "Causes will be displayed here"
            }
            add={
              isLoading ? null :
              userWithPermissions &&
              userWithPermissions.permissions &&
              userWithPermissions.permissions.createRecord &&
              userWithPermissions.permissions.createRecord.causes
            }
            height={"75vh"}
            width={"10vw"}
            color={palettes.criticalControl.primary}
            onAdd={addCauseInput}
          />

          <div
            className="main-area-container"
            id="main-area-container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              position: "relative",
              padding: "0rem 8rem",
            }}
          >
            <div
              className="hazard-container"
              style={{
                position: "absolute",
                top: "0",
              }}
            >
              {bowtieData.hazard ? (
                <Rectangular
                  text={bowtieData.hazard}
                  backgroundColor={palettes.gray.primary}
                  color={bowtieData.hazard ? "#ffffff" : "#333333"}
                  borderColor={palettes.gray.primary}
                  borderWidth="2px"
                  width="150px"
                  fontSize="20px"
                  id="hazard"
                  click={() => {
                    if (
                      userWithPermissions &&
                      userWithPermissions.permissions &&
                      userWithPermissions.permissions.editRecord &&
                      userWithPermissions.permissions.editRecord.main
                    ) {
                      setEditHazard(true);
                    }
                  }}
                />
              ) : !showHazard ? null : (
                isLoading ? <div style={{
                  ...hazardTextAreaStyle,
                  marginBottom: "8rem",
                  resize: "none",
                  flexDirection: 'column'
                }}>
                  {loadingPreview('Hazard will be displayed here', 'hazard-risk')}
                </div> :
                <textarea
                  rows={2}
                  id="hazard"
                  ref={addHazard}
                  disabled={isEditHazardDisabled}
                  onKeyDown={handleOnHazardKeyDown}
                  placeholder="Click to add Hazard"
                  onBlur={handleAddHazard}
                  style={{
                    ...hazardTextAreaStyle,
                    marginBottom: "8rem",
                    resize: "none",
                    textOpacity: "20%",
                  }}
                />
              )}
            </div>

            <div className="risk-scenario-container">
                {isLoading ? <div style={{
                    ...riskScenarioTextAreaStyle,
                    marginBottom: "8rem",
                    resize: "none",
                    flexDirection: 'column'
                  }}>
                    {loadingPreview('Risk will be displayed here', 'hazard-risk')}
                  </div> :
              <textarea
                rows={2}
                id="risk-scenario"
                ref={addInput}
                disabled={isRiskScenarioLoading}
                onKeyDown={handleOnRiskScenarioKeyDown}
                placeholder="Click to add Risk Scenario"
                onBlur={handleAddRiskScenario}
                style={{
                  ...riskScenarioTextAreaStyle,
                  marginBottom: "8rem",
                  resize: "none",
                  textOpacity: "20%",
                  width: "200px",
                }}
              />}
            </div>
          </div>
          <Controls
            title={"Consequences"}
            id="consequences-container"
            content={
              isLoading ? loadingPreview('Consequences will be displayed here') :
              userWithPermissions &&
              userWithPermissions.permissions &&
              userWithPermissions.permissions.createRecord &&
              userWithPermissions.permissions.createRecord.consequences
                ? "Add consequences in this box"
                : "Consequences will be displayed here"
            }
            add={
              isLoading ? null :
              userWithPermissions &&
              userWithPermissions.permissions &&
              userWithPermissions.permissions.createRecord &&
              userWithPermissions.permissions.createRecord.consequences
            }
            height={"75vh"}
            width={"10vw"}
            color={palettes.criticalControl.primary}
            onAdd={addConsequenceInput}
          />
          <Controls
            title={"Mitigating Controls"}
            id="mitigating-controls-container"
            content={
              isLoading ? loadingPreview('Mitigating controls will be displayed here') :
              "Mitigating controls will be displayed here"}
            height={"75vh"}
            width={"20vw"}
            color={palettes.gray.primary}
          />
        </React.Fragment>
      );
    }
    if (diagramMode === DIAGRAM_MODE.BOWTIE) {
      return (
        <React.Fragment>
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <div>
              <GeneralContainer
                title="Causes"
                id="causes-container"
                backgroundColor={palettes.lightBlue.primary}
              >
                <MultiLineContainer
                  mode="single"
                  id="causes"
                  lines={bowtieData.causes}
                  controlLines={bowtieData.causes}
                  form={bowtieData.bowtieConfiguration.forms.causes}
                  backgroundColor={palettes.lightBlue.primary}
                />
              </GeneralContainer>
            </div>
          ) : (
            <Controls
              title={"Causes"}
              id="causes-container"
              content={
                isLoading ? loadingPreview('Causes will be displayed here') :
                userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.causes
                  ? "Add causes in this box"
                  : "Causes will be displayed here"
              }
              add={
                isLoading ? null :
                userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.causes
              }
              height={"75vh"}
              width={"10vw"}
              color={palettes.criticalControl.primary}
              onAdd={addCauseInput}
            />
          )}
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <GeneralContainer
              title="Preventative Controls"
              backgroundColor={palettes.lightGray.background}
              id="preventative-controls-container"
            >
              <MultiLineContainer
                mode="multi"
                controlLines={preventativeControlsLines}
                form={bowtieData.bowtieConfiguration.forms.controls}
                id="preventative_control"
                alignment="right"
                backgroundColor={palettes.lightGray.background}
              />
            </GeneralContainer>
          ) : (
            <Controls
              title={"Preventative Controls"}
              id="preventative-controls-container"
              content={
                isLoading ? loadingPreview('Preventative controls will be displayed here') :
                "Preventative controls will be displayed here"}
              height={"75vh"}
              width={"20vw"}
              color={palettes.gray.primary}
            />
          )}
          <div
            className="main-area-container"
            id="main-area-container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              position: "relative",
              padding: "0rem 8rem",
            }}
          >
            <div
              className="hazard-container"
              style={{
                position: "absolute",
                top: "0",
              }}
            >
              {bowtieData.hazard && !editHazard ? (
                <Rectangular
                  text={bowtieData.hazard}
                  backgroundColor={palettes.gray.primary}
                  color={bowtieData.hazard ? "#ffffff" : "#333333"}
                  borderColor={palettes.gray.primary}
                  borderWidth="2px"
                  width="150px"
                  fontSize="20px"
                  id="hazard"
                  click={() => {
                    if (
                      userWithPermissions &&
                      userWithPermissions.permissions &&
                      userWithPermissions.permissions.editRecord &&
                      userWithPermissions.permissions.editRecord.main
                    ) {
                      setEditHazard(true);
                    }
                  }}
                />
              ) : !showHazard ? null : (
                isLoading ? <div style={{
                  ...hazardTextAreaStyle,
                  marginBottom: "8rem",
                  resize: "none",
                  flexDirection: 'column'
                }}>
                  {loadingPreview('Hazard will be displayed here', 'hazard-risk')}
                </div> :
                <textarea
                  rows={2}
                  id="hazard"
                  defaultValue={bowtieData.hazard ? bowtieData.hazard : ""}
                  ref={addHazard}
                  disabled={isEditHazardDisabled}
                  onKeyDown={handleOnHazardKeyDown}
                  placeholder="Click to add Hazard"
                  onBlur={handleUpdateHazard}
                  style={{
                    ...hazardTextAreaStyle,
                    marginBottom: "8rem",
                    resize: "none",
                    textOpacity: "20%",
                  }}
                />
              )}
            </div>

            <div className="risk-scenario-container">
              {!editRiskScenario ? (
                <Rectangular
                  text={
                    riskScenarioInputValue
                      ? riskScenarioInputValue
                      : bowtieData.scenario
                  }
                  color={bowtieData.scenario ? "#ffffff" : "#333333"}
                  backgroundColor={palettes.criticalControl.primary}
                  borderColor={palettes.criticalControl.primary}
                  borderWidth="2px"
                  width="200px"
                  fontSize="20px"
                  id="risk-scenario"
                  hasCircleRight={Boolean(bowtieData.consequences)}
                  hasCircleLeft={Boolean(bowtieData.causes)}
                  link={bowtieData.scenarioRecord?.linkUrl}
                  click={handleClickEditRiskScenario}
                />
              ) : (
                
              isLoading ? <div style={{
                  ...riskScenarioTextAreaStyle,
                  marginBottom: "8rem",
                  resize: "none",
                  flexDirection: 'column'
                }}>
                  {loadingPreview('Risk will be displayed here', 'hazard-risk')}
                </div> :
                <textarea
                  rows={2}
                  id="risk-scenario"
                  defaultValue={bowtieData.scenario ? bowtieData.scenario : ""}
                  ref={addInput}
                  disabled={isEditRiskScenarioDisabled}
                  onKeyDown={handleOnRiskScenarioKeyDown}
                  placeholder="Click to add Risk Scenario"
                  onBlur={handleUpdateRiskScenario}
                  style={{
                    ...riskScenarioTextAreaStyle,
                    resize: "none",
                    textOpacity: "20%",
                    width: "200px",
                  }}
                />
              )}
            </div>
          </div>
          {bowtieData &&
          bowtieData.consequences &&
          bowtieData.consequences.length > 0 ? (
            <GeneralContainer
              title="Mitigating Controls"
              backgroundColor={palettes.lightGray.background}
              id="mitigating-controls-container"
            >
              <MultiLineContainer
                mode="multi"
                controlLines={mitigatingControlsLines}
                form={bowtieData.bowtieConfiguration.forms.controls}
                backgroundColor={palettes.lightGray.background}
                id="mitigating_control"
                alignment="left"
              />
            </GeneralContainer>
          ) : (
            <Controls
              title={"Mitigating Controls"}
              id="mitigating-controls-container"
              content={
                isLoading ? loadingPreview('Mitigating controls will be displayed here') :
                "Mitigating controls will be displayed here"}
              height={"75vh"}
              width={"20vw"}
              color={palettes.gray.primary}
            />
          )}

          {bowtieData &&
          bowtieData.consequences &&
          bowtieData.consequences.length > 0 ? (
            <GeneralContainer
              title="Consequences"
              id="consequences-container"
              backgroundColor={palettes.lightBlue.primary}
            >
              <MultiLineContainer
                mode="single"
                id="consequences"
                lines={bowtieData.consequences}
                controlLines={bowtieData.consequences}
                form={bowtieData.bowtieConfiguration.forms.consequences}
                alignment="right"
                backgroundColor={palettes.lightBlue.primary}
              />
            </GeneralContainer>
          ) : (
            <Controls
              title={"Consequences"}
              id="consequences-container"
              content={
                isLoading ? loadingPreview('Consequences will be displayed here') :
                userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.consequences
                  ? "Add consequences in this box"
                  : "Consequences will be displayed here"
              }
              add={
                isLoading ? null :
                userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.consequences
              }
              height={"75vh"}
              width={"10vw"}
              color={palettes.criticalControl.primary}
              onAdd={addConsequenceInput}
            />
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
          <GeneralContainer
            title="Preventative Controls"
            backgroundColor={palettes.lightGray.background}
            id="preventative-controls-container"
          >
            <MultiLineContainer
              mode="multi"
              controlLines={preventativeControlsLines}
              form={bowtieData.bowtieConfiguration.forms.controls}
              id="preventative_control"
              alignment="right"
              backgroundColor={palettes.lightGray.background}
            />
          </GeneralContainer>
        ) : (
          <Controls
            title={"Preventative Controls"}
            id="preventative-controls-container"
            content={
              isLoading ? loadingPreview('Preventative controls will be displayed here') :
              "Preventative controls will be displayed here"}
            height={"75vh"}
            width={"20vw"}
            color="#B6B6B6"
          />
        )}
        {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
          <GeneralContainer
            title="Causes"
            id="causes-container"
            backgroundColor={palettes.lightBlue.primary}
          >
            <MultiLineContainer
              mode="single"
              id="causes"
              lines={bowtieData.causes}
              form={bowtieData.bowtieConfiguration.forms.causes}
              controlLines={bowtieData.causes}
              backgroundColor={palettes.lightBlue.primary}
            />
          </GeneralContainer>
        ) : (
          <Controls
            title={"Causes"}
            id="causes-container"
            content={
              isLoading ? loadingPreview('Causes will be displayed here') :
              userWithPermissions &&
              userWithPermissions.permissions &&
              userWithPermissions.permissions.createRecord &&
              userWithPermissions.permissions.createRecord.causes
                ? "Add causes in this box"
                : "Causes will be displayed here"
            }
            add={
              isLoading ? null :
              userWithPermissions &&
              userWithPermissions.permissions &&
              userWithPermissions.permissions.createRecord &&
              userWithPermissions.permissions.createRecord.causes
            }
            height={"75vh"}
            width={"10vw"}
            color={palettes.criticalControl.primary}
            onAdd={addCauseInput}
          />
        )}
        <div
          className="main-area-container"
          id="main-area-container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            position: "relative",
            padding: "0rem 8rem",
          }}
        >
          <div
            className="hazard-container"
            style={{
              position: "absolute",
              top: "0",
            }}
          >
            {bowtieData.hazard && !editHazard ? (
              <Rectangular
                text={bowtieData.hazard}
                backgroundColor={palettes.gray.primary}
                color={bowtieData.hazard ? "#ffffff" : "#333333"}
                borderColor={palettes.gray.primary}
                borderWidth="2px"
                width="150px"
                fontSize="20px"
                id="hazard"
                click={() => {
                  if (
                    userWithPermissions &&
                    userWithPermissions.permissions &&
                    userWithPermissions.permissions.editRecord &&
                    userWithPermissions.permissions.editRecord.main
                  ) {
                  setEditHazard(true);
                  }
                }}
              />
            ) : !showHazard ? null : (
              isLoading ? <div style={{
                ...hazardTextAreaStyle,
                marginBottom: "8rem",
                resize: "none",
                flexDirection: 'column'
              }}>
                {loadingPreview('Hazard will be displayed here', 'hazard-risk')}
              </div> :
              <textarea
                rows={2}
                id="hazard"
                defaultValue={bowtieData.hazard ? bowtieData.hazard : ""}
                ref={addHazard}
                disabled={isEditHazardDisabled}
                onKeyDown={handleOnHazardKeyDown}
                placeholder="Click to add Hazard"
                onBlur={handleUpdateHazard}
                style={{
                  ...hazardTextAreaStyle,
                  marginBottom: "8rem",
                  resize: "none",
                  textOpacity: "20%",
                }}
              />
            )}
          </div>

          <div className="risk-scenario-container">
            {!editRiskScenario ? (
              <Rectangular
                text={
                  riskScenarioInputValue
                    ? riskScenarioInputValue
                    : bowtieData.scenario
                }
                color={bowtieData.scenario ? "#ffffff" : "#333333"}
                backgroundColor={palettes.criticalControl.primary}
                borderColor={palettes.criticalControl.primary}
                borderWidth="2px"
                width="200px"
                fontSize="20px"
                id="risk-scenario"
                hasCircleRight={Boolean(bowtieData.consequences)}
                hasCircleLeft={Boolean(bowtieData.causes)}
                link={bowtieData.scenarioRecord?.linkUrl}
                click={handleClickEditRiskScenario}
              />
            ) : (
              isLoading ? <div style={{
                ...riskScenarioTextAreaStyle,
                marginBottom: "8rem",
                resize: "none",
                flexDirection: 'column'
              }}>
                {loadingPreview('Risk will be displayed here', 'hazard-risk')}
              </div> :
              <textarea
                rows={2}
                id="risk-scenario"
                defaultValue={bowtieData.scenario ? bowtieData.scenario : ""}
                ref={addInput}
                disabled={isEditRiskScenarioDisabled}
                onKeyDown={handleOnRiskScenarioKeyDown}
                placeholder="Click to add Risk Scenario"
                onBlur={handleUpdateRiskScenario}
                style={{
                  ...riskScenarioTextAreaStyle,
                  resize: "none",
                  textOpacity: "20%",
                  width: "200px",
                }}
              />
            )}
          </div>
        </div>
        {bowtieData &&
        bowtieData.consequences &&
        bowtieData.consequences.length > 0 ? (
          <GeneralContainer
            title="Consequences"
            id="consequences-container"
            backgroundColor={palettes.lightBlue.primary}
          >
            <MultiLineContainer
              mode="single"
              id="consequences"
              lines={bowtieData.consequences}
              controlLines={bowtieData.consequences}
              form={bowtieData.bowtieConfiguration.forms.consequences}
              alignment="right"
              backgroundColor={palettes.lightBlue.primary}
            />
          </GeneralContainer>
        ) : (
          <Controls
            title={"Consequences"}
            id="consequences-container"
            content={
              isLoading ? loadingPreview('Consequences will be displayed here') :
              userWithPermissions &&
              userWithPermissions.permissions &&
              userWithPermissions.permissions.createRecord &&
              userWithPermissions.permissions.createRecord.consequences
                ? "Add consequences in this box"
                : "Consequences will be displayed here"
            }
            add={
              isLoading ? null :
              userWithPermissions &&
              userWithPermissions.permissions &&
              userWithPermissions.permissions.createRecord &&
              userWithPermissions.permissions.createRecord.consequences
            }
            height={"75vh"}
            width={"10vw"}
            color={palettes.criticalControl.primary}
            onAdd={addConsequenceInput}
          />
        )}
        {bowtieData &&
        bowtieData.consequences &&
        bowtieData.consequences.length > 0 ? (
          <GeneralContainer
            title="Mitigating Controls"
            backgroundColor={palettes.lightGray.background}
            id="mitigating-controls-container"
          >
            <MultiLineContainer
              mode="multi"
              controlLines={mitigatingControlsLines}
              form={bowtieData.bowtieConfiguration.forms.controls}
              id="mitigating_control"
              alignment="left"
              backgroundColor={palettes.lightGray.background}
            />
          </GeneralContainer>
        ) : (
          <Controls
            title={"Mitigating Controls"}
            id="mitigating-controls-container"
            content={
              isLoading ? loadingPreview('Mitigating controls will be displayed here') :
              "Mitigating controls will be displayed here"}
            height={"75vh"}
            width={"20vw"}
            color="#B6B6B6"
          />
        )}
      </React.Fragment>
    );
  };
  return (
    <div
      className="diagram-container"
      id="diagram"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        margin: "auto",
        position: "relative",
        // overflow: "visible",
      }}
      ref={diagramRef}
    >
      <React.Fragment>{diagramRender()}</React.Fragment>
    </div>
  );
}
