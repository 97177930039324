import {createSelector} from 'reselect';

const selectRaw = (state) => state.user;


const selectUser = createSelector([selectRaw], user => user.user);

const selectUserError = createSelector([selectRaw], user => user.userLoading);

const selectUserLoading = createSelector([selectRaw], user => user.userLoading);

const userSelectors = {
    selectUser,
    selectUserError,
    selectUserLoading
}

export default userSelectors;