import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import "./Rectangular.css";
import LinkIcon from "../../../../assets/icons/LinkIcon";
import UnlinkIcon from "../../../../assets/icons/UnlinkIcon";
import { fromPairs } from "lodash";
import BasicModal from "../../../Modal/BasicModal";
import ActionModalContent from "../../../Modal/ActionModalContent";
import diagramActions from "../../../../modules/diagram/diagramActions";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { palettes } from "../../../../environment/environment";

export const LINE_DIRECTION = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
};

export default function Rectangular(props) {
  const [mouseEnter, setMouseEnter] = useState(false);
  const [open, setOpen] = useState(false);
  const externalLinkRef = useRef();
  const deleteLinkRef = useRef();

  const dispatch = useDispatch();

  const {
    text,
    width,
    backgroundColor,
    color,
    borderWidth,
    borderColor,
    fontSize,
    className,
    id,
    hasCircleRight,
    hasCircleLeft,
    hasLine,
    lineColor,
    lineDirection,
    link,
    status,
    containerBackgroundColor,
    click,
    form,
  } = props;

  const formWorkflowSteps = form && form.workflowSteps && form.workflowSteps;
  const border = backgroundColor === palettes.lightBlue.primary ? 
  palettes.criticalControl.primary : borderColor;

  const draftWorkflowStep =
    formWorkflowSteps &&
    formWorkflowSteps.find((workflowStep) => workflowStep.draftMarker);

  const draftWorkflowStepLabel =
    draftWorkflowStep &&
    draftWorkflowStep.label.translations &&
    draftWorkflowStep.label.translations[0] &&
    draftWorkflowStep.label.translations[0].value;

  const [rectangularStyle, setRectangularStyle] = useState({
    backgroundColor: "#ffffff",
    color: "#333",
    borderWidth: "1px",
    borderColor: "#333",
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    textAlign: "center",
    borderStyle: "solid",
    borderRadius: "0.6rem",
    zIndex: "3000",
    position: "relative",
    height: id === "hazard" || id === "risk-scenario" ? "100px" : "50px",
  });


  useEffect(() => {
    if (link !== null) {
      setRectangularStyle((oldRectangularStyle) => {
        return {
          ...oldRectangularStyle,
          width,
          backgroundColor,
          color,
          borderWidth,
          borderColor,
          fontSize,
        };
      });
    }
  }, [props]);

  useEffect(() => {
    if (link === null) {
      setRectangularStyle((oldRectangularStyle) => {
        return {
          ...oldRectangularStyle,
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: palettes.criticalControl.primary,
          backgroundColor: "rgb(240, 240, 240)",
          width: "120px",
        };
      });
    }
  }, [props.link]);

  const onDelete = (rawElement) => {
    dispatch(diagramActions.doUnlinkControl(rawElement))
  }
  const isNotControl = id && (id?.includes("cause") || id?.includes("consequence"));
  const isControlIssue = backgroundColor === palettes.controlIssue.primary;

  return (
    <div
      id={id}
      style={{
        ...rectangularStyle,
        fontWeight: isNotControl || isControlIssue ? "300" : "normal",
        color:
        link === null
          ? palettes.text.white
          : isNotControl
          ? palettes.text.white
          : color,
      }}
      className={`rectangular-container ${className}`}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
      onClick={(event) => {
        if (
          click &&
          (!externalLinkRef.current ||
            (externalLinkRef.current &&
              !externalLinkRef.current.contains(event.target))) &&
          (!deleteLinkRef.current ||
            (deleteLinkRef.current &&
              !deleteLinkRef.current.contains(event.target)))
        ) {
          click();
        }
      }}
    >

<BasicModal open={open} setOpen={setOpen}>
        <ActionModalContent
          actionButton={{
            onAction: async () => {
              setOpen(false);
              onDelete(props.rawElement);
            },
            text: "Unlink",
          }}
          onClose={() => {
            setOpen(false);
          }}
          getText={() => `Are you sure you want to remove this control from this Bowtie?`}
        />
      </BasicModal>

      {mouseEnter && link && (
        <div
          className="external-link-container"
          onClick={() => window.open(link)}
          ref={externalLinkRef}
          title="Open in Viking"
        >
          <LinkIcon height={20} width={20} color={border} />
        </div>
      )}
      {mouseEnter && (!id?.includes("cause") && !id?.includes("consequence") && !id?.includes("hazard") && !id?.includes("scenario")) && (
        <div ref={deleteLinkRef} className="delete-link-container" onClick={() => setOpen(true)} title="Unlink">
          <UnlinkIcon height={20} width={20} color={border}/>
        </div>
      )}

      {props.rawElement?.global &&
        <div className="global-icon-container"><FontAwesomeIcon
        icon={faGlobe}
        color={palettes.criticalControl.primary}
        title="Global control"
      /></div>
      }

      { !props.rawElement?.global && ((status && status === "Draft") ||
        (draftWorkflowStepLabel && draftWorkflowStepLabel === status)) && (
        <div
          className="draft-marker"
          style={{
            backgroundColor: borderColor,
            borderColor: containerBackgroundColor,
          }}
          title="Draft"
        ></div>
      )}


      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
        title={text}

      >
        <div
        className="button-radius"
          style={{
            backgroundColor:
              link === null ? "rgb(240, 240, 240)" : backgroundColor,
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="line-clamp"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
              zIndex: 100,
            }}
          >
            <p
              style={{
                margin: 0,
                lineHeight: 1.2,
                whiteSpace: "break-spaces",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineClamp: 3,
              }}
            >
              {text}
            </p>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "115%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {hasCircleRight && <div className="circle circle-right"></div>}
          {hasCircleLeft && <div className="circle circle-left"></div>}
        </div>
      </div>

      {hasLine && (
        <div
          className="line"
          style={{
            background: lineColor,
            top: lineDirection === LINE_DIRECTION.BOTTOM && "107%",
            bottom: lineDirection === LINE_DIRECTION.TOP && "105%",
          }}
        ></div>
      )}
    </div>
  );
}

Rectangular.propTypes = {
  text: PropTypes.string,
  key: PropTypes.string,
  fontSize: PropTypes.string,
  status: PropTypes.string,
  containerBackgroundColor: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  hasCircleRight: PropTypes.bool,
  hasCircleLeft: PropTypes.bool,
  hasLine: PropTypes.bool,
  lineColor: PropTypes.string,
  lineDirection: PropTypes.string,
  editable: PropTypes.bool,
  parentId: PropTypes.string,
};
