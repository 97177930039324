import { keys } from "../../helpers/config";
import Request from "../../helpers/request";
import { buildUrl } from "../../helpers/urlManager";
import { toast } from "material-react-toastify";
import queryString from "query-string";
import _ from "lodash";
import { riskScenarioPageSize } from "../../environment/environment";

const WarningToastNotification = ({ message }) => {
  return (
    <div className="d-flex flex-row py-3 px-2 align-items-center w-100">
      <p className="mb-0" style={{ lineHeight: "1.2rem" }}>
        {message}
      </p>
      <button
        className="btn btn-outline-warning h-60"
        onClick={() => window.location.reload()}
      >
        RETRY
      </button>
    </div>
  );
};

const unique = (array) => {
  return array.filter((item, index, array) => {
    return array.indexOf(item) === index;
  });
};

export const notify = (message) =>
  toast(<WarningToastNotification message={message} />);

const requiredMitigatingControlsFields = [
  "captionField",
  "consequences",
  "criticalOrNonCriticalField",
  "effectiveOrNotEffectiveField",
  "recordlinkField",
];
const requiredPreventativeControlFields = [
  "captionField",
  "causes",
  "criticalOrNonCriticalField",
  "effectiveOrNotEffectiveField",
  "recordlinkField",
];

export default class RecordService {
  env = "";
  envConfig = {};

  constructor(env) {
    this.env = env;
    this.envConfig = keys[env];
  }

  fetchRecordsByForm(formId, query) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `forms/${formId}/records` },
      query
    );

    return Request.doRequest({
      url: requestUrl,
      method: "GET",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
    });
  }

  addCause(causePayload, bowtieConfiguration) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple` },
      {}
    );

    return Request.doRequest({
      url: requestUrl,
      method: "POST",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: [
        {
          formId: bowtieConfiguration.forms.causes.id,
          moduleId: bowtieConfiguration.forms.causes.moduleId,
          fields: {
            [bowtieConfiguration.preventativeControls.causes.captionField]:
              causePayload.value,
          },
          hierarchies: {},
          status:
            bowtieConfiguration.forms.causes.form.workflowSteps[0].label
              .translations[0].value,
        },
      ],
    });
  }

  addConsequence(consequencePayload, bowtieConfiguration) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple` },
      {}
    );

    return Request.doRequest({
      url: requestUrl,
      method: "POST",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: [
        {
          formId: bowtieConfiguration.forms.consequences.id,
          moduleId: bowtieConfiguration.forms.consequences.moduleId,
          fields: {
            [bowtieConfiguration.mitigatingControls.consequences.captionField]:
              consequencePayload.value,
          },
          hierarchies: {},
          status:
            bowtieConfiguration.forms.consequences.form.workflowSteps[0].label
              .translations[0].value,
        },
      ],
    });
  }

  addPreventativeControl(preventativeControlPayload, bowtieConfiguration) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple` },
      {}
    );

    return Request.doRequest({
      url:
        requestUrl +
        (preventativeControlPayload.existingElement
          ? `/${preventativeControlPayload.existingElement.id}`
          : ""),
      method: preventativeControlPayload.existingElement ? "PUT" : "POST",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: preventativeControlPayload.existingElement
        ? {
            fields: {
              Causes: _.uniq([
                ...(preventativeControlPayload.existingElement.fields.find(
                  (field) => field.name === "Causes"
                )?.value || []),
                String(preventativeControlPayload.parentId),
              ]),
            },
            hierarchies: {},
            status: preventativeControlPayload.existingElement.status,
            formId: bowtieConfiguration.forms.controls.id,
          }
        : [
            {
              sourceId: preventativeControlPayload.sourceId,
              formId: bowtieConfiguration.forms.controls.id,
              moduleId: bowtieConfiguration.forms.controls.moduleId,
              fields: {
                [bowtieConfiguration.preventativeControls.captionField]:
                  preventativeControlPayload.value,
                "Preventative/Mitigating": {
                  value: "Preventative",
                },
                Causes: [String(preventativeControlPayload.parentId)],
              },
              hierarchies: {},
              status:
                bowtieConfiguration.forms.controls.form.workflowSteps[0].label
                  .translations[0].value,
            },
          ],
    });
  }

  addMitigatingControl(mitigatingControlPayload, bowtieConfiguration) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple` },
      {}
    );

    return Request.doRequest({
      url:
        requestUrl +
        (mitigatingControlPayload.existingElement
          ? `/${mitigatingControlPayload.existingElement.id}`
          : ""),
      method: mitigatingControlPayload.existingElement ? "PUT" : "POST",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: mitigatingControlPayload.existingElement
        ? {
            fields: {
              Consequences: _.uniq([
                ...(mitigatingControlPayload.existingElement.fields.find(
                  (field) => field.name === "Consequences"
                )?.value || []),
                String(mitigatingControlPayload.parentId),
              ]),
            },
            hierarchies: {},
            status: mitigatingControlPayload.existingElement.status,
            formId: bowtieConfiguration.forms.controls.id,
          }
        : [
            {
              sourceId: mitigatingControlPayload.sourceId,
              formId: bowtieConfiguration.forms.controls.id,
              moduleId: bowtieConfiguration.forms.controls.moduleId,
              fields: {
                [bowtieConfiguration.mitigatingControls.captionField]:
                  mitigatingControlPayload.value,
                "Preventative/Mitigating": {
                  value: "Mitigating",
                },
                Consequences: [String(mitigatingControlPayload.parentId)],
              },
              hierarchies: {},
              status:
                bowtieConfiguration.forms.controls.form.workflowSteps[0].label
                  .translations[0].value,
            },
          ],
    });
  }

  addRiskScenario(form, labelField, labelValue) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple` },
      {}
    );

    return Request.doRequest({
      url: requestUrl,
      method: "POST",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: [
        {
          formId: form.id,
          hierarchies: {},
          fields: {
            [labelField]: labelValue,
          },
          status:
            form.workflowSteps.length > 0
              ? form.workflowSteps[0].label.translations[0].value
              : "Draft",
        },
      ],
    });
  }

  updateRiskScenarioHazard(
    riskScenarioRecord,
    hazardFieldLabel,
    hazardFieldValue
  ) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple/${riskScenarioRecord.id}` },
      {}
    );

    return Request.doRequest({
      url: requestUrl,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: {
        formId: riskScenarioRecord.formId,
        formName: riskScenarioRecord.formName,
        status: riskScenarioRecord.status,
        id: riskScenarioRecord.id,
        fields: {
          [hazardFieldLabel]: hazardFieldValue,
        },
        hierarchies: {},
      },
    });
  }

  updateRiskScenarioRecord(riskScenarioId, riskScenarioPayload) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple/${riskScenarioId}` },
      {}
    );

    const editableField = riskScenarioPayload.fields.find((field) => {
      if (riskScenarioPayload.mitigating) {
        return field.name === "Mitigating Controls";
      }

      if (riskScenarioPayload.preventative) {
        return field.name === "Preventative Controls";
      }

      return field.name === "Preventative Controls";
    });

    return Request.doRequest({
      url: requestUrl,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: {
        formId: riskScenarioPayload.formId,
        formName: riskScenarioPayload.formName,
        status: riskScenarioPayload.status,
        id: riskScenarioId,
        fields: {
          [riskScenarioPayload.mitigating
            ? "Mitigating Controls"
            : "Preventative Controls"]: editableField
            ? _.uniq([
                ...editableField.value,
                String(riskScenarioPayload.linkedRecordId),
              ])
            : [String(riskScenarioPayload.linkedRecordId)],
        },
        hierarchies: {},
      },
    });
  }

  updateRiskScenarioField(bowtieData, value) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `records/simple/${bowtieData.scenarioRecord.id}`,
      },
      {}
    );
    return Request.doRequest({
      url: requestUrl,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: {
        id: bowtieData.scenarioRecord.id,
        formId: bowtieData.scenarioRecord.formId,
        formName: bowtieData.scenarioRecord.formName,
        status: bowtieData.scenarioRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.scenario.captionField]: value,
        },
        hierarchies: {},
      },
    });
  }

  updateCause(bowtieData, causeRecord, value) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple/${causeRecord.uuid}` },
      {}
    );
    return Request.doRequest({
      url: requestUrl,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: {
        id: causeRecord.uuid,
        formId: bowtieData.bowtieConfiguration.forms.causes.id,
        status: causeRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.preventativeControls.causes
            .captionField]: value,
        },
        hierarchies: {},
      },
    });
  }

  updateConsequence(bowtieData, consequenceRecord, value) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple/${consequenceRecord.uuid}` },
      {}
    );
    return Request.doRequest({
      url: requestUrl,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: {
        id: consequenceRecord.uuid,
        formId: bowtieData.bowtieConfiguration.forms.consequences.id,
        status: consequenceRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.mitigatingControls.consequences
            .captionField]: value,
        },
        hierarchies: {},
      },
    });
  }

  updatePreventativeControl(bowtieData, preventativeControlRecord, value) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `records/simple/${preventativeControlRecord.id}`,
      },
      {}
    );
    return Request.doRequest({
      url: requestUrl,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: {
        id: preventativeControlRecord.id,
        formId: bowtieData.bowtieConfiguration.forms.controls.id,
        status: preventativeControlRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.preventativeControls.captionField]:
            value,
        },
        hierarchies: {},
      },
    });
  }

  updateMitigatingControl(bowtieData, mitigatingControlRecord, value) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `records/simple/${mitigatingControlRecord.id}`,
      },
      {}
    );
    return Request.doRequest({
      url: requestUrl,
      method: "PUT",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
      data: {
        id: mitigatingControlRecord.id,
        formId: bowtieData.bowtieConfiguration.forms.controls.id,
        status: mitigatingControlRecord.status,
        fields: {
          [bowtieData.bowtieConfiguration.mitigatingControls.captionField]:
            value,
        },
        hierarchies: {},
      },
    });
  }

  fetchRecord(recordId) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/${recordId}` },
      {}
    );
    return Request.doRequest({
      url: requestUrl,
      method: "GET",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
    });
  }

  fetchRecords(formId, recordIds) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `forms/${formId}/records` },
      {
        filter: `id:in:${_.compact(recordIds).join(",")}`,
      }
    );

    return Request.doRequest({
      url: requestUrl,
      method: "GET",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
    });
  }

  fetchRecordsByFormId(params) {
    const { formId, pageSize, page, isNewRiskScenario } = params;
    const authorizationHeader = localStorage.getItem("idToken");
    const parsedSearch = queryString.parse(window.location.search);
    const isEqual = isNewRiskScenario ? 'eq' : 'neq';
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `forms/${formId}/records`,
      },
      {
        pageSize,
        page,
        filter: !parsedSearch?.records ? "" : `id:${isEqual}:${parsedSearch?.records}`,
        sort: 'Risk Scenario',
        order_by: 'ASC'
      }
    );

    return Request.doRequest({
      url: requestUrl,
      method: "GET",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
    });
  }

  fetchAddonsByFormId(formId) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      {
        env: this.env,
        endpoint: `addon/list`,
      },
      {
        formId,
      }
    );

    return Request.doRequest({
      url: requestUrl,
      method: "GET",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
    });
  }

  fetchAddonByIdAndFormId(addonId, formId) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `addon/${addonId}` },
      { formId }
    );

    return Request.doRequest({
      url: requestUrl,
      method: "GET",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
    });
  }

  fetchFormInfoByModuleIdAndFormId(moduleId, formId) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl({
      env: this.env,
      endpoint: `modules/${moduleId}/forms/${formId}`,
    });

    return Request.doRequest({
      url: requestUrl,
      method: "GET",
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
    });
  }

  async doTransformScenarioRecords(
    bowtieRecordsResponse, 
    bowtieConfiguration, 
    mainFormPayload
  ) {
    const { scenario: riskScenario } = bowtieConfiguration;
    const {
      payload: {
        data: {
          result: { results },
        },
      },
    } = bowtieRecordsResponse;
    const parsedSearch = queryString.parse(window.location.search);

    let records = results
      .filter((result) => result.fields)
      .map((result) => {

        return {
          value: result.id,
          label: result.fields.find(
            (field) => field.name === riskScenario.captionField
          )?.value,
          selected: result.id === Number(parsedSearch.records),
          itemColor:
            mainFormPayload.workflowSteps && mainFormPayload.workflowSteps.length > 0
              ? mainFormPayload.workflowSteps.find((workflowStep) => {
                  return (
                    workflowStep.label &&
                    workflowStep.label.translations &&
                    workflowStep.label.translations[0].value === result.status
                  );
                })?.backgroundColor
              : null,
        };
      })
      .filter((result) => result.label);

      return records;
  }

  async doFetchScenarioRecords(params) {
    const { formId, isNewRiskScenario } = params;

    return await this.fetchRecordsByFormId({
      formId: formId,
      pageSize: riskScenarioPageSize,
      page: 1,
      isNewRiskScenario
    });
  }

  async doFilterScenarioRecord(record, bowtieConfiguration, mainFormPayload, isNewRiskScenario = false) {
    const bowtieRecordsPayload = await this.doFetchScenarioRecords({
      formId: record?.formId,
      isNewRiskScenario
    });
    
    const { scenario: riskScenario } = bowtieConfiguration;

    if (bowtieRecordsPayload.success) {
      const form = mainFormPayload.payload.data.result;

        let records = await this.doTransformScenarioRecords(
          bowtieRecordsPayload,
          bowtieConfiguration,
          form
        );
        const selectedRecord = records.find((record) => record.selected);
        records = records.filter((record) => !record.selected);

        if (!!selectedRecord) {
          records.unshift(selectedRecord);
        } else {
          records.unshift({
            value: record.id,
            label: record?.fields.find(
              (field) => field.name === riskScenario.captionField
            )?.value,
            selected: true,
            itemColor: '#0ba600'
          });
        }
 
        return records;
    }
  }

  async doGetBowtieConfiguration(configuration) {
    let bowtieConfiguration = {};

    try {
      bowtieConfiguration = JSON.parse(configuration);
    } catch (err) {
      throw new Error(err);
    }

    return bowtieConfiguration;
  }
  
  async doGetMainFormPayload(params) {
    const { moduleId, id } = params;
    let mainFormPayload = {};

    try {
      mainFormPayload = await this.fetchFormInfoByModuleIdAndFormId(
        moduleId,
        id
      );
    } catch (err) {
      throw new Error(err);
    }

    return mainFormPayload
  }

  async mapBowtieData(record, bowtieConfiguration, mainFormPayload) {
    const bowtieData = {};
    bowtieData.scenarioRecord = record;

    if (!record.fields) {
      throw new Error("Missing parameter 'fields' in record body");
    }

    if (!bowtieConfiguration.scenario) {
      throw new Error("Missing paremeter 'scenario' in bowtie configuration");
    }

    if (!bowtieConfiguration.mitigatingControls) {
      throw new Error(
        "Missing parameter 'mitigatingControls' in bowtie configuration"
      );
    }

    if (!bowtieConfiguration.forms) {
      throw new Error("Missing parameter 'forms' in bowtie configuration");
    }

    if (bowtieConfiguration.mitigatingControls) {
      requiredMitigatingControlsFields.forEach((requiredField) => {
        if (!bowtieConfiguration.mitigatingControls[requiredField]) {
          throw new Error(
            `Missing parameter '${requiredField}' in 'mitigatingControls' field`
          );
        }
      });
    }

    if (!bowtieConfiguration.preventativeControls) {
      throw new Error(
        "Missing parameter 'preventativeControls' in bowtie configuration"
      );
    }

    if (bowtieConfiguration.preventativeControls) {
      requiredPreventativeControlFields.forEach((requiredField) => {
        if (!bowtieConfiguration.preventativeControls[requiredField]) {
          throw new Error(
            `Missing parameter '${requiredField}' in 'preventativeControls' field`
          );
        }
      });
    }

    const { fields } = record;
    const { scenario, hazard, mitigatingControls, preventativeControls } =
      bowtieConfiguration;

    const scenarioField = fields.find(
      (field) => field.name === scenario.captionField
    );

    if (mainFormPayload.success) {
      const causesFormPayload = await this.fetchFormInfoByModuleIdAndFormId(
        bowtieConfiguration.forms.causes.moduleId,
        bowtieConfiguration.forms.causes.id
      );

      const consequencesFormPayload =
        await this.fetchFormInfoByModuleIdAndFormId(
          bowtieConfiguration.forms.consequences.moduleId,
          bowtieConfiguration.forms.consequences.id
        );

      const controlsFormPayload = await this.fetchFormInfoByModuleIdAndFormId(
        bowtieConfiguration.forms.controls.moduleId,
        bowtieConfiguration.forms.controls.id
      );

      bowtieConfiguration.forms.causes.form =
        causesFormPayload.payload.data.result;
      bowtieConfiguration.forms.consequences.form =
        consequencesFormPayload.payload.data.result;

      bowtieConfiguration.forms.controls.form =
        controlsFormPayload.payload.data.result;

      bowtieConfiguration.forms.main.form = mainFormPayload.payload.data.result;

      localStorage.setItem(
        "bowtieConfiguration",
        JSON.stringify(bowtieConfiguration)
      );
    }

    // Adding bowtie configuration
    bowtieData.bowtieConfiguration = bowtieConfiguration;

    bowtieData.scenario = scenarioField.value;

    if (hazard) {
      const hazardField = fields.find(
        (field) => field.name === hazard.captionField
      );

      if (hazardField) {
        bowtieData.hazard = hazardField.value;
      } else {
        bowtieData.hazard = null;
      }
    }

    const mitiGatingControlRecordLinkField = fields.find(
      (field) => mitigatingControls.recordlinkField === field.name
    );

    // Fetching all preventative controls with causes

    const preventativeControlsRecordLinkField = fields.find(
      (field) => preventativeControls.recordlinkField === field.name
    );

    if (
      !mitiGatingControlRecordLinkField &&
      !preventativeControlsRecordLinkField
    ) {
      const standaloneCauses = localStorage.getItem("standaloneCauses" + "_" + window.location.search);
      const standaloneConsequences = localStorage.getItem(
        "standaloneConsequences" + "_" + window.location.search
      );

      if (standaloneCauses) {
        const parsedStandaloneCauses = JSON.parse(standaloneCauses);
        bowtieData.causes = [...parsedStandaloneCauses];
      }

      if (standaloneConsequences) {
        const parsedStandaloneConsequences = JSON.parse(standaloneConsequences);
        bowtieData.consequences = [...parsedStandaloneConsequences];
      }
      return bowtieData;
    }

    const bowtieDataPromisePool = await Promise.all([
      this.fetchMitigatingControlsWithConsequences(
        bowtieConfiguration,
        mitiGatingControlRecordLinkField,
        mitigatingControls
      ),
      this.fetchPreventativeControlsWithCauses(
        bowtieConfiguration,
        preventativeControlsRecordLinkField,
        preventativeControls
      ),
    ]);

    const mitiGatingControlRecords = bowtieDataPromisePool[0];
    const preventativeControlRecords = bowtieDataPromisePool[1];

    if (mitiGatingControlRecords && mitiGatingControlRecords.records) {
      bowtieData.mitigatingControls = [
        ..._.compact(mitiGatingControlRecords.records),
      ];
    } else {
      bowtieData.mitigatingControls = [];
    }

    if (preventativeControlRecords && preventativeControlRecords.records) {
      bowtieData.preventativeControls = [
        ..._.compact(preventativeControlRecords.records),
      ];
    } else {
      bowtieData.preventativeControls = [];
    }

    const uniqueCauses = bowtieData.preventativeControls
      .map((preventativeControl) => preventativeControl.causes)
      .flat()
      .filter(
        (cause, index, array) =>
          index === array.findIndex((c) => c.id === cause.id)
      );

    const uniqueConsequences = bowtieData.mitigatingControls
      .map((mitigatingControl) => mitigatingControl.consequences)
      .flat()
      .filter(
        (consequence, index, array) =>
          index === array.findIndex((c) => c.id === consequence.id)
      );

    bowtieData.causes = uniqueCauses.map((uniqueCause) => {
      const ownPreventativeControls = bowtieData.preventativeControls.filter(
        (preventativeControl) =>
          preventativeControl &&
          preventativeControl.causes &&
          preventativeControl.causes.length > 0 &&
          preventativeControl.causes.find(
            (cause) => cause && cause && cause.id === uniqueCause.id
          )
      );

      return {
        ...uniqueCause,
        preventativeControls: ownPreventativeControls,
        form: bowtieConfiguration.forms.causes,
      };
    });

    delete bowtieData.preventativeControls;

    bowtieData.consequences = uniqueConsequences.map((uniqueConsequence) => {
      const ownMitigatingControls = bowtieData.mitigatingControls.filter(
        (mitigatingControl) =>
          mitigatingControl &&
          mitigatingControl.consequences &&
          mitigatingControl.consequences.length > 0 &&
          mitigatingControl.consequences.find(
            (consequence) =>
              consequence &&
              consequence &&
              consequence.id === uniqueConsequence.id
          )
      );

      return {
        ...uniqueConsequence,
        mitigatingControls: ownMitigatingControls,
        form: bowtieConfiguration.forms.consequences,
      };
    });

    delete bowtieData.mitigatingControls;

    const standaloneCauses = localStorage.getItem("standaloneCauses" + "_" + window.location.search);
    const standaloneConsequences = localStorage.getItem(
      "standaloneConsequences" + "_" + window.location.search
    );

    if (standaloneCauses) {
      const parsedStandaloneCauses = JSON.parse(standaloneCauses);
      bowtieData.causes = [...bowtieData.causes, ...parsedStandaloneCauses];
    }

    if (standaloneConsequences) {
      const parsedStandaloneConsequences = JSON.parse(standaloneConsequences);
      bowtieData.consequences = [
        ...bowtieData.consequences,
        ...parsedStandaloneConsequences,
      ];
    }

    return bowtieData;
  }

  // Fetching the mitigating controls with consequences

  async fetchMitigatingControlsWithConsequences(
    bowtieConfiguration,
    mitiGatingControlRecordLinkField,
    mitigatingControls
  ) {
    let toBeFetchedConsequences = [];
    if (
      mitiGatingControlRecordLinkField &&
      mitiGatingControlRecordLinkField.value &&
      mitiGatingControlRecordLinkField.value.length > 0
    ) {
      const recordsPayload = await this.fetchRecords(
        bowtieConfiguration.forms?.controls?.id,
        mitiGatingControlRecordLinkField.value
      );
      if (
        recordsPayload.success &&
        recordsPayload.payload &&
        recordsPayload.payload.data &&
        recordsPayload.payload.data.result &&
        recordsPayload.payload.data.result.results &&
        recordsPayload.payload.data.result.results.length > 0
      ) {
        const records = recordsPayload.payload.data.result.results;
        const consequencesFields = records
          .filter((record) => record.fields)
          .map((record) => {
            const consequencesRecordLinkField = record.fields.find(
              (field) =>
                field.name === mitigatingControls.consequences.recordLinkField
            );
            if (
              consequencesRecordLinkField &&
              consequencesRecordLinkField.value &&
              consequencesRecordLinkField.value.length > 0
            ) {
              toBeFetchedConsequences = toBeFetchedConsequences.concat(
                consequencesRecordLinkField.value
              );
              return {
                recordId: record.id,
                consequenceId: consequencesRecordLinkField.value,
              };
            } else {
              return { recordId: null, consequenceId: null };
            }
          });
        const consequencesRecordPayload = await this.fetchRecords(
          bowtieConfiguration.forms?.consequences?.id,
          consequencesFields.map((field) => field.consequenceId)?.flat()
        );
        if (
          consequencesRecordPayload &&
          consequencesRecordPayload.success &&
          consequencesRecordPayload.payload &&
          consequencesRecordPayload.payload.data &&
          consequencesRecordPayload.payload.data.result &&
          consequencesRecordPayload.payload.data.result.results &&
          consequencesRecordPayload.payload.data.result.results.length > 0
        ) {
          const consequences =
            consequencesRecordPayload.payload.data.result.results;
          const mappedConsequences = consequences
            .filter((consequence) => consequence.fields)
            .map((consequence) => {
              const consequencesCaptionField = consequence.fields.find(
                (field) =>
                  field.name === mitigatingControls.consequences.captionField
              );

              return {
                id: consequence && consequence.id && consequence.id,
                uuid: consequence && consequence.id && consequence.id,
                value:
                  consequencesCaptionField &&
                  consequencesCaptionField.value &&
                  consequencesCaptionField.value,
                status: consequence.status,
                linkUrl:
                  consequence &&
                  consequence.linkUrl &&
                  consequence.linkUrl.length > 0
                    ? consequence.linkUrl
                    : null,
              };
            });
          const mappedRecords = records
            .filter((record) => record.fields)
            .map((record) => {
              let effectiveOrNotEffectiveField = null;
              const captionField = record.fields.find(
                (field) => field.name === mitigatingControls.captionField
              );

              const criticalOrNonCriticalField = record.fields.find(
                (field) =>
                  field.name === mitigatingControls.criticalOrNonCriticalField
              );

              if (
                mitigatingControls.effectiveOrNotEffectiveField &&
                record[mitigatingControls.effectiveOrNotEffectiveField]
              ) {
                effectiveOrNotEffectiveField = {
                  value: {
                    value:
                      record[mitigatingControls.effectiveOrNotEffectiveField],
                  },
                };
              } else {
                effectiveOrNotEffectiveField = record.fields.find(
                  (field) =>
                    field.name ===
                    mitigatingControls.effectiveOrNotEffectiveField
                );
              }

              const recordConsequencesFields = consequencesFields.find(
                (field) => field.recordId === record.id
              )?.consequenceId;

              const globalField = record.fields.find((field) => field.name === "Global")
              

              return {
                id: record.id,
                value: captionField.value,
                uuid: record.id,
                status: record.status,
                linkUrl:
                  record.linkUrl && record.linkUrl.length > 0
                    ? record.linkUrl
                    : null,
                consequences: recordConsequencesFields
                  ? mappedConsequences.filter((consequence) =>
                      recordConsequencesFields.includes(String(consequence.id))
                    )
                  : [],
                effectiveOrNotEffective:
                  effectiveOrNotEffectiveField &&
                  effectiveOrNotEffectiveField.value &&
                  effectiveOrNotEffectiveField.value.value,
                criticalOrNonCritical:
                  criticalOrNonCriticalField &&
                  criticalOrNonCriticalField.value &&
                  criticalOrNonCriticalField.value.value,
                global: Boolean(globalField && globalField.value)
              };
            });

          return mappedRecords && mappedRecords.length > 0
            ? {
                records: mappedRecords,
                toBeFetchedConsequences: unique(toBeFetchedConsequences),
              }
            : { records: [], toBeFetchedConsequences };
        }
      }
    }
  }

  // Fetching all preventative controls with causes

  async fetchPreventativeControlsWithCauses(
    bowtieConfiguration,
    preventativeControlsRecordLinkField,
    preventativeControls
  ) {
    let toBeFetchedCauses = [];
    if (
      preventativeControlsRecordLinkField &&
      preventativeControlsRecordLinkField.value &&
      preventativeControlsRecordLinkField.value.length > 0
    ) {
      const recordsPayload = await this.fetchRecords(
        bowtieConfiguration.forms?.controls?.id,
        preventativeControlsRecordLinkField.value
      );
      if (
        recordsPayload.success &&
        recordsPayload.payload &&
        recordsPayload.payload.data &&
        recordsPayload.payload.data.result &&
        recordsPayload.payload.data.result.results &&
        recordsPayload.payload.data.result.results.length > 0
      ) {
        const records = recordsPayload.payload.data.result.results;
        const causesFields = records
          .filter((record) => record.fields)
          .map((record) => {
            const causesRecordLinkField = record.fields.find(
              (field) =>
                field.name === preventativeControls.causes.recordLinkField
            );
            if (
              causesRecordLinkField &&
              causesRecordLinkField.value &&
              causesRecordLinkField.value.length > 0
            ) {
              toBeFetchedCauses = toBeFetchedCauses.concat(
                causesRecordLinkField.value
              );
              return {
                recordId: record.id,
                causeId: causesRecordLinkField.value,
              };
            } else {
              return { recordId: null, causeId: null };
            }
          });
        const causesRecordPayload = await this.fetchRecords(
          bowtieConfiguration.forms?.causes?.id,
          causesFields.map((field) => field.causeId)?.flat()
        );

        if (
          causesRecordPayload &&
          causesRecordPayload.success &&
          causesRecordPayload.payload &&
          causesRecordPayload.payload.data &&
          causesRecordPayload.payload.data.result &&
          causesRecordPayload.payload.data.result.results &&
          causesRecordPayload.payload.data.result.results.length > 0
        ) {
          const causes = causesRecordPayload.payload.data.result.results;
          const mappedCauses = causes
            .filter((cause) => cause.fields)
            .map((cause) => {
              const causesCaptionField = cause.fields.find(
                (field) =>
                  field.name === preventativeControls.causes.captionField
              );

              return {
                id: cause && cause.id && cause.id,
                uuid: cause && cause.id && cause.id,
                status: cause.status,
                value:
                  causesCaptionField &&
                  causesCaptionField.value &&
                  causesCaptionField.value,
                linkUrl:
                  cause && cause.linkUrl && cause.linkUrl.length > 0
                    ? cause.linkUrl
                    : null,
              };
            });
          const mappedRecords = records
            .filter((record) => record.fields)
            .map((record) => {
              let effectiveOrNotEffectiveField = null;
              const captionField = record.fields.find(
                (field) => field.name === preventativeControls.captionField
              );

              const criticalOrNonCriticalField = record.fields.find(
                (field) =>
                  field.name === preventativeControls.criticalOrNonCriticalField
              );

              if (
                preventativeControls.effectiveOrNotEffectiveField &&
                record[preventativeControls.effectiveOrNotEffectiveField]
              ) {
                effectiveOrNotEffectiveField = {
                  value: {
                    value:
                      record[preventativeControls.effectiveOrNotEffectiveField],
                  },
                };
              } else {
                effectiveOrNotEffectiveField = record.fields.find(
                  (field) =>
                    field.name ===
                    preventativeControls.effectiveOrNotEffectiveField
                );
              }

              const recordCausesFields = causesFields.find(
                (field) => field.recordId === record.id
              )?.causeId;

              const globalField = record.fields.find((field) => field.name === "Global")


              return {
                id: record && record.id && record.id,
                uuid: record && record.id && record.id,
                value: captionField?.value,
                status: record.status,
                linkUrl:
                  record.linkUrl && record.linkUrl.length > 0
                    ? record.linkUrl
                    : null,
                causes: recordCausesFields
                  ? mappedCauses.filter((cause) =>
                      recordCausesFields.includes(String(cause.id))
                    )
                  : [],
                effectiveOrNotEffective:
                  effectiveOrNotEffectiveField &&
                  effectiveOrNotEffectiveField.value &&
                  effectiveOrNotEffectiveField.value.value,
                criticalOrNonCritical:
                  criticalOrNonCriticalField &&
                  criticalOrNonCriticalField.value &&
                  criticalOrNonCriticalField.value.value,
                global: Boolean(globalField && globalField.value)
              };
            });

          return mappedRecords && mappedRecords.length > 0
            ? {
                records: mappedRecords,
                toBeFetchedCauses: unique(toBeFetchedCauses),
              }
            : { records: [], toBeFetchedCauses };
        }
      }
    }
  }

  doUpdateRecord(recordId, recordPayload) {
    const authorizationHeader = localStorage.getItem("idToken");
    const requestUrl = buildUrl(
      { env: this.env, endpoint: `records/simple/${recordId}` },
      {}
    );

    return Request.doRequest({
      url: requestUrl,
      method: "PUT",
      data: recordPayload,
      headers: {
        Authorization: "Bearer " + authorizationHeader,
        "x-api-key": this.envConfig.apiKey,
      },
    });
  }
}
