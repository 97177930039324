import { createSelector } from "reselect";

const selectRaw = (state) => state.diagram;

const selectBowtieData = createSelector(
  [selectRaw],
  (diagram) => diagram.bowtieData
);

const selectLoading = createSelector([selectRaw], (diagram) => diagram.loading);

const selectErrorMessage = createSelector(
  [selectRaw],
  (diagram) => diagram.errorMessage
);

const selectAddLoading = createSelector(
  [selectRaw],
  (diagram) => diagram.addBowtieData.loading
);

const selectAddErrorMessage = createSelector(
  [selectRaw],
  (diagram) => diagram.addBowtieData.errorMessage
);

const selectFormRecords = createSelector(
  [selectRaw],
  (diagram) => diagram.formRecords.items
);

const selectFormRecordsLoading = createSelector(
  [selectRaw],
  (diagram) => diagram.formRecords.loading
);

const selectFormRecordsErrorMessage = createSelector(
  [selectRaw],
  (diagram) => diagram.formRecords.errorMessage
);

const selectScenarioRecords = createSelector(
  [selectRaw],
  (diagram) => diagram.riskScenarioRecords
);

const selectQueues = createSelector(
  [selectRaw],
  (diagram) => diagram.queue
);

const selectDiagramLayout = createSelector(
  [selectRaw],
  (diagram) => diagram.diagramLayout
);

const diagramSelectors = {
  selectBowtieData,
  selectLoading,
  selectErrorMessage,
  selectAddLoading,
  selectAddErrorMessage,
  selectFormRecords,
  selectFormRecordsLoading,
  selectFormRecordsErrorMessage,
  selectScenarioRecords,
  selectQueues,
  selectDiagramLayout
};

export default diagramSelectors;
