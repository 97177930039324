import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import diagramActions from "../../../../modules/diagram/diagramActions";
import diagramSelectors from "../../../../modules/diagram/diagramSelectors";
import RecordService from "../../../../modules/diagram/diagramService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import "./TextArea.css";
import { palettes } from "../../../../environment/environment";
import LoadingControls from "../../../../Components/Items/LoadingComponent/LoadingControls";

const recordService = new RecordService("test");

const TextArea = (props) => {
  const {
    id,
    elementId,
    addElement,
    removeElement,
    lineDirection,
    parentId,
    index,
    defaultValue,
    line,
    setElementToEdit,
    form,
  } = props;

  const recordListContainerRef = useRef();

  const [cursorIndex, setCursorIndex] = useState(0);

  const [inputValue, setInputValue] = useState(defaultValue);

  const dispatch = useDispatch();
  const LINE_DIRECTION = {
    TOP: "TOP",
    BOTTOM: "BOTTOM",
  };
  const [textAreaStyle, setTextAreaStyle] = useState({
    color: palettes.text.primary,
    width: defaultValue ? "120px" : "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    textAlign: "center",
    borderRadius: "0.6rem",
    zIndex: "3000",
    position: "relative",
    outline: "none",
    padding: "10px",
    height: "47px",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: palettes.criticalControl.primary,
    backgroundColor: palettes.lightWhite.primary,
  });

  const bowtieData = useSelector(diagramSelectors.selectBowtieData);

  const records = useSelector(diagramSelectors.selectFormRecords);

  const recordsLoading = useSelector(diagramSelectors.selectFormRecordsLoading);

  const addInput = useRef();
  const [disabled, setDisabled] = useState(false);

  const [showRecordList, setShowRecordList] = useState(true);

  useEffect(() => {
    if (addInput && addInput.current && addInput.current.focus) {
      addInput.current.focus();
    }

    return () => {
      dispatch(diagramActions.doResetFormRecords());
    };
  }, []);

  const getControlsFromBowtieData = useCallback(() => {
    let controls = [];

    if (bowtieData?.causes) {
      bowtieData.causes.forEach((cause) => {
        controls = [...controls, ...cause.preventativeControls];
      });
    }

    if (bowtieData?.consequences) {
      bowtieData.consequences.forEach((consequence) => {
        controls = [...controls, ...consequence.mitigatingControls];
      });
    }

    return controls.filter((control, index, controlArray) => {
      return (
        controlArray.findIndex((c) => c.id === control.id) === index &&
        typeof control.id === "number"
      );
    });
  }, [bowtieData]);

  const filterRelevenatControls = (records) => {
    const diagramControls = getControlsFromBowtieData();

    return records?.filter((record) => {
        const globalField = record.fields.find((field) => {
          return field.name === "Global";
        });

        return (
          (globalField && globalField.value) ||
          diagramControls.find(
            (diagramControl) => diagramControl.id === record.id
          )
        );
      })
      .map((record) => {
        const globalField = record.fields.find((field) => {
          return field.name === "Global";
        });

        return {
          ...record,
          global: Boolean(globalField && globalField.value),
        };
      }).sort((recordA, recordB) => {
        return recordA.displayText.localeCompare(recordB.displayText)
      });
  };  


  const onAddControl = async () => {
    if (inputValue?.length > 0) {
      if (typeof elementId !== "number") {
        setShowRecordList(false);
        addElement({
          id: elementId,
          value: addInput.current.value,
          parentId,
        });
        setDisabled(true);
      } else {
        if (inputValue !== defaultValue) {
          setDisabled(true);
          setTextAreaStyle((oldstyle) => {
            oldstyle.borderStyle = "solid";
            oldstyle.color = "rgba(51, 51, 51, 0.5)";
            oldstyle.borderColor = palettes.criticalControl.rgb[5];

            return oldstyle;
          });
          if (id === "preventative_control") {
            await recordService.updatePreventativeControl(
              bowtieData,
              line,
              addInput.current.value
            );
          } else {
            await recordService.updateMitigatingControl(
              bowtieData,
              line,
              addInput.current.value
            );
          }
          line.value = inputValue;
          setElementToEdit(null);
        } else {
          setDisabled(false);
          setElementToEdit(null);
        }
      }
    } else {
      if (typeof elementId !== "number") {
        removeElement({
          id: elementId,
          parentId,
        });
      }
      setDisabled(false);
      setElementToEdit(null);
    }

    setTextAreaStyle((oldstyle) => {
      oldstyle.borderStyle = "solid";
      oldstyle.color = "rgba(51, 51, 51, 0.5)";
      oldstyle.borderColor = palettes.criticalControl.rgb[5];
      return oldstyle;
    });
  };

  const onAddExistingControl = (record) => {
    setInputValue(
      record.fields.find((field) => field.name === "Control Name")?.value
    );
    setShowRecordList(false);

    const recordControlName = record.fields.find(
      (field) => field.name === "Control Name"
    )?.value;

    addElement({
      id: elementId,
      value: recordControlName,
      parentId,
      existingElement: record,
    });

    setDisabled(true);
    setTextAreaStyle((oldstyle) => {
      oldstyle.borderStyle = "solid";
      oldstyle.color = "rgba(51, 51, 51, 0.5)";
      oldstyle.borderColor = palettes.criticalControl.rgb[5];
      return oldstyle;
    });
  };

  const handleKeyDownEvent = async (event) => {
    if ((event.code === "Enter" || event.code === 'NumpadEnter') && !cursorIndex && !event.shiftKey) {
      event.preventDefault();
      addInput.current.blur();
    }
  }

  const handleOnChangeEvent = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    setInputValue(event.target.value);
    if (event.target.value?.length >= 3) {
      dispatch(
        diagramActions.doFetchRecodsByFormId(form.id, {
          filter: `Control Name:like:${event.target.value}`,
        })
      );
    } else {
      dispatch(diagramActions.doResetFormRecords());
    }
  }

  return (
    <div
      id={id}
      key={index}
      style={{
        position: "relative",
        width: "200px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
      }}
      className={`rectangular-container`}
      onKeyDown={(event) => {
        event.stopPropagation();
        if (event.code === "ArrowDown") {
          const downIndex =
            cursorIndex + 1 > filterRelevenatControls(records).length ? cursorIndex : cursorIndex + 1;
          const recordItem = document.getElementById(
            `record-item-${downIndex - 1}`
          );
          recordItem &&
            recordItem.scrollIntoView({ block: "nearest", inline: "nearest" });

          setCursorIndex(downIndex);
        }

        if (event.code === "ArrowUp") {
          const upIndex = !cursorIndex ? 0 : cursorIndex - 1;
          const recordItem = document.getElementById(
            `record-item-${upIndex - 1}`
          );
          recordItem &&
            recordItem.scrollIntoView({ block: "nearest", inline: "nearest" });

          setCursorIndex(upIndex);
        }

        if (event.code === "Enter" && cursorIndex > 0) {
          onAddExistingControl(filterRelevenatControls(records)[cursorIndex - 1]);
        }
      }}
    >
      <div>
        <textarea
          onKeyDown={handleKeyDownEvent}
          rows={3}
          cols={10}
          id={`${id + "_input"}`}
          ref={addInput}
          disabled={disabled}
          value={inputValue}
          onBlur={onAddControl}
          onChange={handleOnChangeEvent}
          style={{
            ...textAreaStyle,
            marginBottom: "8rem",
            resize: "none",
            textOpacity: "20%",
          }}
        />
        {disabled 
          && <div className={`box-loading-controls 
            ${defaultValue ? 'r3' : 'r05'}`}>
              <LoadingControls />
          </div>}
      </div> 
      <div
        style={{
          background: palettes.criticalControl.primary,
          top: lineDirection === LINE_DIRECTION.BOTTOM && "92%",
          bottom: lineDirection === LINE_DIRECTION.TOP && "92%",
          height: "28px",
          width: "2px",
          position: "absolute",
        }}
      ></div>
      {showRecordList && !defaultValue && (
        <div className="record-item-list-container" ref={recordListContainerRef}>
          {recordsLoading ? (
            <CircularProgress sx={{ color: palettes.criticalControl.primary }} />
          ) : filterRelevenatControls(records)?.length > 0 ? (
            filterRelevenatControls(records)?.map((record, index) => {
              return (
                <div
                  className={`record-item ${
                    cursorIndex - 1 === index ? "record-item-active" : ""
                  }`}
                  onClick={() => onAddExistingControl(record)}
                  id={`record-item-${index}`}
                >
                  <p>
                    {
                      record.fields.find(
                        (field) => field.name === "Control Name"
                      )?.value 
                    }
                  </p>
                  {record.global && (
                    <FontAwesomeIcon
                      icon={faGlobe}
                      color={palettes.criticalControl.primary}
                    />
                  )}
                </div>
              );
            })
          ) : (
            "... press enter to create ..."
          )}
        </div>
      )}

      {showRecordList && !defaultValue && (
        <div
          className="text-area-add-button"
          onClick={async (event) => {
            event.preventDefault();
            event.stopPropagation();
            setShowRecordList(false);
            if (addInput.current && addInput.current.value) {
              addElement({
                id: elementId,
                value: addInput.current.value,
                parentId,
              });
            } else {
              removeElement({
                id: elementId,
                parentId,
              });
            }
            setDisabled(true);
            setTextAreaStyle((oldstyle) => {
              oldstyle.borderStyle = "solid";
              oldstyle.color = "rgba(51, 51, 51, 0.5)";
              oldstyle.borderColor = palettes.criticalControl.rgb[5];
              return oldstyle;
            });
          }}
        ></div>
      )}
    </div>
  );
};

export default TextArea;
