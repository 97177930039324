import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { DIAGRAM_MODE } from "../../../../modules/filter/filterReducer";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import diagramActions from "../../../../modules/diagram/diagramActions";
import userSelectors from "../../../../modules/user/userSelectors";
import { palettes } from "../../../../environment/environment";

function AddPlus(props) {
  const { x1, y1, onMouseEnter, onMouseLeave } = props;
  return (
    <>
      <circle
        cx={x1}
        cy={y1}
        r="10"
        fill="white"
        stroke={palettes.criticalControl.rgb[8]}
        strokeWidth="3"
        style={{
          position: "relative",
          zIndex: "5000 !important",
          cursor: "pointer",
        }}
        strokeDasharray="0"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <line
        x1={x1}
        y1={y1 - 7}
        x2={x1}
        y2={y1 + 7}
        stroke={palettes.criticalControl.rgb[8]}
        strokeWidth="3"
        style={{
          position: "relative",
          zIndex: "5000 !important",
          cursor: "pointer",
        }}
        strokeDasharray="0"
      />
      <line
        x1={x1 - 7}
        y1={y1}
        x2={x1 + 7}
        y2={y1}
        stroke={palettes.criticalControl.rgb[8]}
        strokeWidth="3"
        style={{
          position: "relative",
          zIndex: "5000 !important",
          cursor: "pointer",
        }}
        strokeDasharray="0"
      />
    </>
  );
}

function calculateLineSkeletonDimensions({
  bowtieData,
  setHeaderDimensions,
  setCausesContainerDimensions,
  setConsequencesContainerDimensions,
  setPreventativeControlContainerDimensions,
  setMitigatingControlContainerDimensions,
  setRiskScenarioDimensions,
  setHazardDimensions,
  setDiagramDimensions,
  setCausesDimensions,
  setConsequencesDimensions,
  setZoomControlDimensions,
  diagramMode,
}) {
  const causesContainer = document.getElementById("causes-container");
  const header = document.getElementById("header");
  const diagram = document.getElementById("diagram");
  const consequencesContainer = document.getElementById(
    "consequences-container"
  );
  const preventativeControlsContainer = document.getElementById(
    "preventative-controls-container"
  );
  const mitigatingControlContainer = document.getElementById(
    "mitigating-controls-container"
  );

  const riskScenario = document.getElementById("risk-scenario");

  const hazard = document.getElementById("hazard");

  const zoomControlContainer = document.getElementById("diagram-zoom-controls");

  if (header) {
    setHeaderDimensions(header.getBoundingClientRect());
  }

  if (causesContainer) {
    setCausesContainerDimensions(causesContainer.getBoundingClientRect());
  }

  if (consequencesContainer) {
    setConsequencesContainerDimensions(
      consequencesContainer.getBoundingClientRect()
    );
  }

  if (preventativeControlsContainer) {
    setPreventativeControlContainerDimensions(
      preventativeControlsContainer.getBoundingClientRect()
    );
  }

  if (mitigatingControlContainer) {
    setMitigatingControlContainerDimensions(
      mitigatingControlContainer.getBoundingClientRect()
    );
  }

  if (riskScenario) {
    setRiskScenarioDimensions({
      x:
        riskScenario.offsetLeft +
        preventativeControlsContainer.clientWidth +
        preventativeControlsContainer.offsetLeft +
        riskScenario.clientWidth / 2 +
        18,
      y: riskScenario.offsetTop - 10,
    });
  }

  if (hazard) {
    setHazardDimensions({
      x:
        hazard.offsetParent.offsetLeft +
        preventativeControlsContainer.clientWidth +
        preventativeControlsContainer.offsetLeft +
        hazard.clientWidth / 2 +
        18,
      y: hazard.clientHeight + 10,
    });
  }

  if (diagram) {
    setDiagramDimensions(diagram.getBoundingClientRect());
  }

  if (zoomControlContainer) {
    setZoomControlDimensions(zoomControlContainer.getBoundingClientRect());
  }

  if (bowtieData && bowtieData?.causes) {
    const causesDimensions = [];

    bowtieData.causes.forEach((cause, index) => {
      const causeDOMElement = document.getElementById(`causes_${index + 1}`);

      const finalPreventativeDOMElement = document.getElementById(
        "final_preventative_control_" + (index + 1)
      );

      const causeDOMElementDimensions =
        causeDOMElement && causeDOMElement.getBoundingClientRect();

      if (finalPreventativeDOMElement && causeDOMElementDimensions) {
        const finalPreventativeDOMElementDimensions =
          finalPreventativeDOMElement.getBoundingClientRect();

        causesDimensions.push({
          causeDOMElementDimensions,
          finalPreventativeDOMElementDimensions,
        });
      } else {
        causesDimensions.push({ causeDOMElementDimensions });
      }
    });
    setCausesDimensions(causesDimensions);
  }

  if (bowtieData && bowtieData.consequences) {
    const consequencesDimensions = [];

    bowtieData.consequences.forEach((consequence, index) => {
      const consequenceDOMElement = document.getElementById(
        `consequences_${index + 1}`
      );

      const consequenceDOMElementDimensions =
        consequenceDOMElement && consequenceDOMElement.getBoundingClientRect();

      const finalMitigatingDOMElement = document.getElementById(
        "final_mitigating_control_" + (index + 1)
      );

      if (finalMitigatingDOMElement && consequenceDOMElementDimensions) {
        const finalMitigatingDOMElementDimensions =
          finalMitigatingDOMElement.getBoundingClientRect();

        consequencesDimensions.push({
          consequenceDOMElementDimensions,
          finalMitigatingDOMElementDimensions,
        });
      } else {
        consequencesDimensions.push({ consequenceDOMElementDimensions });
      }
    });
    setConsequencesDimensions(consequencesDimensions);
  }
}

export default function LineSkeleton(props) {
  const { bowtieData, strokeColor, getDiagramSizeAndPosition, diagramMode } =
    props;

  const [lineStroke, setLineStroke] = useState(1.5);

  const [activeLine, setActiveLine] = useState(null);

  const [scrollTop, setScrollTop] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const [headerDimensions, setHeaderDimensions] = useState(null);

  const [diagramDimensions, setDiagramDimensions] = useState(null);

  const [causesContainerDimensions, setCausesContainerDimensions] =
    useState(null);

  const [causesDimensions, setCausesDimensions] = useState(null);

  const [zoomControlDimensions, setZoomControlDimensions] = useState(null);

  const [consequencesContainerDimensions, setConsequencesContainerDimensions] =
    useState(null);

  const [consequencesDimensions, setConsequencesDimensions] = useState(null);

  const [
    preventativeControlContainerDimensions,
    setPreventativeControlContainerDimensions,
  ] = useState(null);

  const [
    mitigatingControlContainerDimensions,
    setMitigatingControlContainerDimensions,
  ] = useState(null);

  const [riskScenarioDimensions, setRiskScenarioDimensions] = useState(null);

  const [hazardDimensions, setHazardDimensions] = useState(null);

  const userWithPermissions = useSelector(userSelectors.selectUser);

  useEffect(() => {
    const mainAreaContainer = document.querySelector(
      ".diagram-transform-container"
    );

    return () => {
      mainAreaContainer.removeEventListener("scroll", () => {});
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      calculateLineSkeletonDimensions({
        bowtieData,
        setHeaderDimensions,
        setHazardDimensions,
        setCausesContainerDimensions,
        setCausesDimensions,
        setConsequencesContainerDimensions,
        setConsequencesDimensions,
        setPreventativeControlContainerDimensions,
        setMitigatingControlContainerDimensions,
        setRiskScenarioDimensions,
        setDiagramDimensions,
        setZoomControlDimensions,
        diagramMode,
      });
    });
  }, [
    diagramMode,
    getDiagramSizeAndPosition.scale,
    bowtieData.causes,
    bowtieData.consequences,
    scrollTop,
    scrollLeft,
  ]);

  const getDiagramLeftOffset = () => {
    if (diagramMode === DIAGRAM_MODE.BUTTERFLY) {
      return getDiagramSizeAndPosition.scale &&
        getDiagramSizeAndPosition.scale === 1 &&
        preventativeControlContainerDimensions
        ? preventativeControlContainerDimensions.left
        : 0;
    }

    if (diagramMode === DIAGRAM_MODE.BOWTIE) {
      return getDiagramSizeAndPosition.scale &&
        getDiagramSizeAndPosition.scale === 1 &&
        causesContainerDimensions
        ? causesContainerDimensions.left
        : 0;
    }
  };

  const dispatch = useDispatch();

  return (
    <svg
      height="100%"
      width="100%"
      style={{
        position: "absolute",
        zIndex: 2000,
        top: 0,
        left: 0,
      }}
    >
      {diagramDimensions &&
        riskScenarioDimensions &&
        hazardDimensions &&
        zoomControlDimensions &&
        headerDimensions && (
          <>
            <line
              stroke={strokeColor}
              x1={
                diagramMode === DIAGRAM_MODE.BUTTERFLY
                  ? hazardDimensions.x + 223
                  : hazardDimensions.x
              }
              y1={hazardDimensions.y}
              x2={
                diagramMode === DIAGRAM_MODE.BUTTERFLY
                  ? riskScenarioDimensions.x + 223
                  : riskScenarioDimensions.x
              }
              y2={riskScenarioDimensions.y}
            ></line>
          </>
        )}

      {/* Butterfly configuration */}
      {diagramMode === DIAGRAM_MODE.BUTTERFLY &&
        causesContainerDimensions &&
        riskScenarioDimensions &&
        hazardDimensions &&
        headerDimensions &&
        zoomControlDimensions &&
        bowtieData &&
        causesDimensions &&
        causesDimensions.length > 0 &&
        causesDimensions.map(
          (
            {
              causeDOMElementDimensions,
              finalPreventativeDOMElementDimensions,
            },
            index
          ) => {
            const riskScenario = document.getElementById("risk-scenario");
            const preventativeControlsContainer = document.getElementById(
              "preventative-controls-container"
            );
            const causesContainer = document.getElementById("causes-container");
            const causeElement = document.getElementById(
              "causes_" + (index + 1)
            );

            return (
              <g
                strokeWidth={
                  activeLine && activeLine === "causes" + (index + 1)
                    ? lineStroke
                    : "1.5"
                }
                style={{
                  transition: "100ms",
                }}
                key={index}
                stroke={
                  activeLine && activeLine === "causes" + (index + 1)
                    ? palettes.criticalControl.rgb[8]
                    : palettes.criticalControl.primary
                }
                strokeDasharray={
                  activeLine && activeLine === "causes" + (index + 1)
                    ? "8"
                    : undefined
                }
              >
                <line
                  x1={0}
                  y1={
                    causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30
                  }
                  x2={causesContainer.offsetLeft + causesContainer.clientWidth}
                  y2={
                    causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30
                  }
                  stroke={strokeColor}
                ></line>
                <line
                  x1={causesContainer.clientWidth + causesContainer.offsetLeft}
                  y1={
                    causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30
                  }
                  x2={
                    riskScenario.offsetLeft +
                    riskScenario.offsetParent.offsetLeft
                  }
                  y2={riskScenario.offsetTop + riskScenario.clientHeight / 2}
                  stroke={strokeColor}
                ></line>
                {activeLine && activeLine === "causes" + (index + 1) && (
                  <AddPlus
                    x1={preventativeControlsContainer.clientWidth / 2}
                    y1={
                      causeElement &&
                      causeElement.offsetTop +
                      causeElement.clientHeight / 2 +
                      30
                    }
                    onMouseEnter={() => {
                      setActiveLine("causes" + (index + 1));
                      setLineStroke(4);
                    }}
                    onMouseLeave={() => {
                      setLineStroke(1.5);
                      setActiveLine(null);
                    }}
                  />
                )}
                {userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls && (
                    <line
                      onClick={() =>
                        dispatch(
                          diagramActions.doAddPreventativeControlInput({index, value: null})
                        )
                      }
                      onMouseEnter={() => {
                        setActiveLine("causes" + (index + 1));
                        setLineStroke(4);
                      }}
                      onMouseLeave={() => {
                        setLineStroke(1.5);
                        setActiveLine(null);
                      }}
                      style={{
                        height: "100px",
                        cursor:
                          activeLine &&
                          activeLine === "causes" + (index + 1) &&
                          "pointer",
                      }}
                      x1={0}
                      y1={
                        causeElement && causeElement.offsetTop +
                        causeElement.clientHeight / 2 +
                        30
                      }
                      x2={
                        causesContainer.offsetLeft + causesContainer.clientWidth
                      }
                      y2={
                        causeElement && causeElement.offsetTop +
                        causeElement.clientHeight / 2 +
                        30
                      }
                      stroke="transparent"
                      strokeDasharray="0"
                      strokeWidth="40"
                    ></line>
                  )}
              </g>
            );
          }
        )}

      {diagramMode === DIAGRAM_MODE.BUTTERFLY &&
        consequencesContainerDimensions &&
        riskScenarioDimensions &&
        bowtieData &&
        consequencesDimensions &&
        consequencesDimensions.length > 0 &&
        consequencesDimensions.map(
          (
            {
              consequenceDOMElementDimensions,
              finalMitigatingDOMElementDimensions,
            },
            index
          ) => {
            const riskScenario = document.getElementById("risk-scenario");
            const mitigatingControlsContainer = document.getElementById(
              "mitigating-controls-container"
            );
            const consequencesContainer = document.getElementById(
              "consequences-container"
            );
            const consequenceElement = document.getElementById(
              "consequences_" + (index + 1)
            );

            return (
              <g
                strokeWidth={
                  activeLine && activeLine === "consequences" + (index + 1)
                    ? lineStroke
                    : "1.5"
                }
                style={{
                  transition: "100ms",
                }}
                key={index}
                stroke={
                  activeLine && activeLine === "consequences" + (index + 1)
                    ? palettes.criticalControl.rgb[8]
                    : palettes.criticalControl.primary
                }
                strokeDasharray={
                  activeLine && activeLine === "consequences" + (index + 1)
                    ? "8"
                    : undefined
                }
              >
                <line
                  x1={
                    mitigatingControlsContainer.offsetLeft +
                    mitigatingControlsContainer.clientWidth
                  }
                  y1={
                    consequenceElement &&
                    consequenceElement.offsetTop +
                    consequenceElement.clientHeight / 2 +
                    30
                  }
                  x2={consequencesContainer.offsetLeft}
                  y2={
                    consequenceElement &&
                    consequenceElement.offsetTop +
                    consequenceElement.clientHeight / 2 +
                    30
                  }
                  stroke={strokeColor}
                ></line>
                <line
                  x1={consequencesContainer.offsetLeft}
                  y1={
                    consequenceElement &&
                    consequenceElement.offsetTop +
                    consequenceElement.clientHeight / 2 +
                    30
                  }
                  x2={
                    riskScenario.offsetLeft +
                    riskScenario.offsetParent.offsetLeft +
                    riskScenario.clientWidth +
                    5
                  }
                  y2={riskScenario.offsetTop + riskScenario.clientHeight / 2}
                  stroke={strokeColor}
                ></line>
                {activeLine && activeLine === "consequences" + (index + 1) && (
                  <AddPlus
                    x1={
                      mitigatingControlsContainer.offsetLeft +
                      mitigatingControlsContainer.clientWidth / 2
                    }
                    y1={
                      consequenceElement &&
                      consequenceElement.offsetTop +
                      consequenceElement.clientHeight / 2 +
                      30
                    }
                    onMouseEnter={() => {
                      setActiveLine("causes" + (index + 1));
                      setLineStroke(4);
                    }}
                    onMouseLeave={() => {
                      setLineStroke(1.5);
                      setActiveLine(null);
                    }}
                  />
                )}
                {userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls && (
                    <line
                      onClick={() =>
                        dispatch(
                          diagramActions.doAddMitigatingControlInput({index, value: null})
                        )
                      }
                      onMouseEnter={() => {
                        setActiveLine("consequences" + (index + 1));
                        setLineStroke(4);
                      }}
                      onMouseLeave={() => {
                        setLineStroke(1.5);
                        setActiveLine(null);
                      }}
                      style={{
                        height: "100px",
                        cursor:
                          activeLine &&
                          activeLine === "consequences" + (index + 1) &&
                          "pointer",
                      }}
                      x1={
                        mitigatingControlsContainer.offsetLeft +
                        mitigatingControlsContainer.clientWidth
                      }
                      y1={
                        consequenceElement &&
                        consequenceElement.offsetTop +
                        consequenceElement.clientHeight / 2 +
                        30
                      }
                      x2={consequencesContainer.offsetLeft}
                      y2={
                        consequenceElement &&
                        consequenceElement.offsetTop +
                        consequenceElement.clientHeight / 2 +
                        30
                      }
                      stroke="transparent"
                      strokeDasharray="0"
                      strokeWidth="40"
                    ></line>
                  )}
              </g>
            );
          }
        )}

      {/* Bowtie configuration */}

      {diagramMode === DIAGRAM_MODE.BOWTIE &&
        preventativeControlContainerDimensions &&
        riskScenarioDimensions &&
        causesDimensions &&
        causesDimensions.length > 0 &&
        causesDimensions.map(({ causeDOMElementDimensions }, index) => {
          const causeElement = document.getElementById("causes_" + (index + 1));
          const preventativeControlsContainer = document.getElementById(
            "preventative-controls-container"
          );
          const riskScenario = document.getElementById("risk-scenario");

          return (
            <g
              strokeWidth={
                activeLine && activeLine === "causes" + (index + 1)
                  ? lineStroke
                  : "1.5"
              }
              style={{
                transition: "100ms",
              }}
              key={index}
              stroke={
                activeLine && activeLine === "causes" + (index + 1)
                  ? palettes.criticalControl.rgb[8]
                  : palettes.criticalControl.primary
              }
              strokeDasharray={
                activeLine && activeLine === "causes" + (index + 1)
                  ? "8"
                  : undefined
              }
            >
              <line
                style={{ height: "100px" }}
                x1={causeElement && causeElement.offsetLeft + causeElement.clientWidth}
                y1={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
                x2={
                  preventativeControlsContainer.clientWidth +
                  preventativeControlsContainer.offsetLeft
                }
                y2={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
              ></line>
              <line
                x1={
                  preventativeControlsContainer.clientWidth +
                  preventativeControlsContainer.offsetLeft
                }
                y1={causeElement && causeElement.offsetTop + causeElement.clientHeight / 2 + 30}
                x2={
                  riskScenario.offsetLeft + riskScenario.offsetParent.offsetLeft
                }
                y2={riskScenario.offsetTop + riskScenario.clientHeight / 2}
                // stroke={strokeColor}
              ></line>
              {activeLine && activeLine === "causes" + (index + 1) && (
                <AddPlus
                  x1={
                    preventativeControlsContainer.offsetLeft +
                    preventativeControlsContainer.clientWidth / 2
                  }
                  y1={
                    causeElement && 
                    causeElement.offsetTop + 
                    causeElement.clientHeight / 2 + 30
                  }
                  onMouseEnter={() => {
                    setActiveLine("causes" + (index + 1));
                    setLineStroke(4);
                  }}
                  onMouseLeave={() => {
                    setLineStroke(1.5);
                    setActiveLine(null);
                  }}
                />
              )}
              {userWithPermissions &&
                userWithPermissions.permissions &&
                userWithPermissions.permissions.createRecord &&
                userWithPermissions.permissions.createRecord.controls && (
                  <line
                    onClick={() =>
                      dispatch(
                        diagramActions.doAddPreventativeControlInput({index, value: null})
                      )
                    }
                    onMouseEnter={() => {
                      setActiveLine("causes" + (index + 1));
                      setLineStroke(4);
                    }}
                    onMouseLeave={() => {
                      setLineStroke(1.5);
                      setActiveLine(null);
                    }}
                    style={{
                      height: "100px",
                      cursor:
                        activeLine &&
                        activeLine === "causes" + (index + 1) &&
                        "pointer",
                    }}
                    x1={causeElement && causeElement.offsetLeft + causeElement.clientWidth}
                    y1={
                      causeElement && causeElement.offsetTop +
                      causeElement.clientHeight / 2 +
                      30
                    }
                    x2={
                      preventativeControlsContainer.clientWidth +
                      preventativeControlsContainer.offsetLeft
                    }
                    y2={
                      causeElement && causeElement.offsetTop +
                      causeElement.clientHeight / 2 +
                      30
                    }
                    stroke="transparent"
                    strokeDasharray="0"
                    strokeWidth="40"
                  ></line>
                )}
            </g>
          );
        })} 
        
      {diagramMode === DIAGRAM_MODE.BOWTIE &&
        mitigatingControlContainerDimensions &&
        riskScenarioDimensions &&
        bowtieData &&
        consequencesDimensions &&
        consequencesDimensions.length > 0 &&
        consequencesDimensions.map(
          ({ consequenceDOMElementDimensions }, index) => {
            const consequenceElement = document.getElementById(
              "consequences_" + (index + 1)
            );
            const mitigatingControlsContainer = document.getElementById(
              "mitigating-controls-container"
            );
            const riskScenario = document.getElementById("risk-scenario");

            return (
              <g
                strokeWidth={
                  activeLine && activeLine === "consequences" + (index + 1)
                    ? lineStroke
                    : "1.5"
                }
                style={{
                  transition: "100ms",
                }}
                key={index}
                stroke={
                  activeLine && activeLine === "consequences" + (index + 1)
                    ? palettes.criticalControl.rgb[8]
                    : palettes.criticalControl.primary
                }
                strokeDasharray={
                  activeLine && activeLine === "consequences" + (index + 1)
                    ? "8"
                    : undefined
                }
              >
                <line
                  onClick={() =>
                    dispatch(diagramActions.doAddMitigatingControlInput({index, value: null}))
                  }
                  x1={
                    consequenceElement &&
                    consequenceElement.offsetLeft +
                    consequenceElement.offsetParent.offsetLeft
                  }
                  y1={
                    consequenceElement &&
                    consequenceElement.offsetTop +
                    consequenceElement.clientWidth / 2 -
                    5
                  }
                  x2={mitigatingControlsContainer.offsetLeft}
                  y2={
                    consequenceElement &&
                    consequenceElement.offsetTop +
                    consequenceElement.clientWidth / 2 -
                    5
                  }
                ></line>
                <line
                  x1={mitigatingControlsContainer.offsetLeft}
                  y1={
                    consequenceElement &&
                    consequenceElement.offsetTop +
                    consequenceElement.clientWidth / 2 -
                    5
                  }
                  x2={
                    riskScenario.offsetLeft +
                    riskScenario.clientWidth +
                    riskScenario.offsetParent.offsetLeft +
                    5
                  }
                  y2={riskScenario.offsetTop + riskScenario.clientHeight / 2}
                ></line>
                {activeLine && activeLine === "consequences" + (index + 1) && (
                  <AddPlus
                    x1={
                      mitigatingControlsContainer.offsetLeft +
                      mitigatingControlsContainer.clientWidth / 2
                    }
                    y1={
                      consequenceElement &&
                      consequenceElement.offsetTop +
                      consequenceElement.clientWidth / 2 -
                      5
                    }
                    onMouseEnter={() => {
                      setActiveLine("causes" + (index + 1));
                      setLineStroke(4);
                    }}
                    onMouseLeave={() => {
                      setLineStroke(1.5);
                      setActiveLine(null);
                    }}
                  />
                )}
                {userWithPermissions &&
                  userWithPermissions.permissions &&
                  userWithPermissions.permissions.createRecord &&
                  userWithPermissions.permissions.createRecord.controls && (
                    <line
                      onClick={() =>
                        dispatch(
                          diagramActions.doAddMitigatingControlInput({index, value: null})
                        )
                      }
                      onMouseEnter={() => {
                        setActiveLine("consequences" + (index + 1));
                        setLineStroke(4);
                      }}
                      onMouseLeave={() => {
                        setLineStroke(1.5);
                        setActiveLine(null);
                      }}
                      style={{
                        height: "100px",
                        cursor:
                          activeLine &&
                          activeLine === "consequences" + (index + 1) &&
                          "pointer",
                      }}
                      x1={
                        consequenceElement &&
                        consequenceElement.offsetLeft +
                        consequenceElement.offsetParent.offsetLeft
                      }
                      y1={
                        consequenceElement &&
                        consequenceElement.offsetTop +
                        consequenceElement.clientWidth / 2 -
                        5
                      }
                      x2={mitigatingControlsContainer.offsetLeft}
                      y2={
                        consequenceElement &&
                        consequenceElement.offsetTop +
                        consequenceElement.clientWidth / 2 -
                        5
                      }
                      stroke="transparent"
                      strokeDasharray="0"
                      strokeWidth="40"
                    ></line>
                  )}
              </g>
            );
          }
        )}
    </svg>
  );
}

LineSkeleton.propTypes = {
  bowtieData: PropTypes.object,
  diagramMode: PropTypes.string,
  strokeColor: PropTypes.string,
};
