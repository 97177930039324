import React from "react";
import "./Button.css";
import { palettes } from "../../../../environment/environment";

const Button = ({ text, buttonClicked }) => {
  const transformedTextArray = text.split("");
  transformedTextArray.pop();

  const transformedText = transformedTextArray.join("");

  return (
    <button
      className="add-button"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        border: `1.5px solid ${palettes.criticalControl.primary}`,
        marginTop: "8.5px",
        color: palettes.criticalControl.primary,
        padding: "6px 0",
        position: "absolute",
        bottom: "-40px",
        left: "0px",
        width: "210px",
        zIndex: 8999,
      }}
      onClick={buttonClicked}
    >
      <div style={{ marginRight: "0.2rem" }}>+</div>
      <div>{transformedText}</div>
    </button>
  );
};

export default Button;
