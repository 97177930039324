import { useEffect, useState } from "react";
import querystring from 'query-string';
import { useLocation } from "react-router-dom";
import { AuthService,  useUser} from "@myosh/myosh-login";

const authService = new AuthService();

export default function UsernameGuard(props) {
  const location = useLocation();
  const [usernameValid, setUsernameValid] = useState(false);

  const userPayload = useUser();

  useEffect(() => {
    if (location && location.search) {
      const parsedQuery = querystring.parse(location.search);

      if (
        parsedQuery &&
        (parsedQuery.userName || parsedQuery.username) &&
        userPayload &&
        userPayload.state.user
      ) {
        const incomingUsername = parsedQuery.userName || parsedQuery.username;
        const currentUsername = userPayload.state.user.prefered_username;

        if (
          incomingUsername &&
          currentUsername &&
          incomingUsername.toLowerCase() !== currentUsername.toLowerCase()
        ) {
          localStorage.clear("initialParams");
          localStorage.setItem("initialParams", location.search)
          setUsernameValid(false);
          authService.logout();
          return;
        }

        setUsernameValid(true);
      } else {
        setUsernameValid(true);
      }
    }
  }, [location.search]);

  return usernameValid && props.children;
}
