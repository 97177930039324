import { useState, useEffect, useRef } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import querystring from "query-string";
import { LoginCallback, LogoutCallback, Login } from "@myosh/myosh-login";
import PrivateRoute from "./shared/PrivateRoute";
import NoMatchRoute from "./shared/NoMatchRoute";
import Main from "../../views/main/Main";
import UsernameGuard from "../../guards/UsernameGuard";

const GuardedMain = () => (
  <UsernameGuard>
    <Main />
  </UsernameGuard>
);



export default function RoutesComponent() {
  const [username, setUsername] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const onLoginSuccess = (results) => {
    if (results) {
      localStorage.setItem("idToken", results.idToken);
      localStorage.setItem("refreshToken", results.refreshToken);
      localStorage.setItem("schema", results.selectedSchema);
    }

    const storedInitialParams = localStorage.getItem("initialParams");
    history.push(`/${storedInitialParams ? storedInitialParams : ""}`);
  };

  useEffect(() => {
    if (location && location.search) {
      const parsedQuery = querystring.parse(location.search);
      if (parsedQuery && Object.keys(parsedQuery).length > 0) {
        if (parsedQuery.userName) {
          setUsername(parsedQuery.userName);
        }
      }
    }
  }, []);
  

  return (
    <Switch>
      <Route path="/auth/cb/logout">
        <LogoutCallback />
      </Route>
      <Route path="/auth/cb/silent">
        <div>Silent renewal callback</div>
      </Route>
      <Route path="/auth/cb">
        <LoginCallback />
      </Route>
      <Route path="/login">
        <Login onLoginSuccess={onLoginSuccess} username={username} />
      </Route>
      <PrivateRoute exact path="/" component={GuardedMain} />
      <Route path="*">
        <NoMatchRoute />
      </Route>
    </Switch>
  );
}
