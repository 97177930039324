import { CRITICAL_ACTIVE, NON_EFFECTIVE_ACTIVE, TOGGLE_DIAGRAM } from "./filterTypes";



const toggleCriticalFilter = (toggle) => async (dispatch) => {
  dispatch({
    type: CRITICAL_ACTIVE,
    payload: toggle,
  });
};

const toggleNonEffectiveFilter = (toggle) => async (dispatch) => {
  dispatch({
    type: NON_EFFECTIVE_ACTIVE,
    payload: toggle,
  });
};


const toggleDiagramMode = (toggle) => async (dispatch) => {
    localStorage.setItem("diagramMode", toggle);
    dispatch({
        type: TOGGLE_DIAGRAM,
        payload: toggle
    })
}

const filterActions = {toggleCriticalFilter, toggleNonEffectiveFilter, toggleDiagramMode};

export default filterActions;