import "./styles/LoadingControls.css"
import React, { useEffect } from 'react'
import ProgressBar from "../ProgressBar/ProgressBar" 

export default function LoadingControls(props) {
  const { className = '' } = props;

    useEffect(() => {
        ProgressBar.start()
        return () => {
          ProgressBar.done()
        }
      }, [])

    return <div className={`loading-control-container ${className}`}>
          <span>.</span>
          <span>.</span>
          <span>.</span>
    </div>
}