import React from "react";
import CirclePlus from "../CirclePlus";

const Controls = ({ title, content, add, width, height, color, onAdd, id, textColor }) => {
  return (
    <div
      id={id}
      className="button-radius"
      style={{
        border: add ? `1px dashed ${color}` : `1px dashed ${color}`,
        padding: "12px 22px",
        display: "inline-block",
        minWidth: width,
        height,
        marginRight: "1rem",
      }}
    >
      <h4
        style={{
          fontWeight: "bold",
          marginBottom: "4rem",
          textAlign: "center",
          color: '#333'
        }}
      >
        {title}
      </h4>

      <div
        style={{
          padding: "130px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "8px",
        }}
      >
        <div style={{ textAlign: "center", color }}>
          {!add && (
            <p
              style={{
                margin: "auto",
                marginTop: "35px",
                width: "55%",
                fontSize: "12px",
              }}
            >
              {content}
            </p>
          )}
          {add && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                position: "relative",
                zIndex: 4000
              }}
              onClick={() => {
                if(onAdd) {
                  onAdd();
                }
              }}
            >
              <div>
                <CirclePlus />
              </div>
              <p style={{ marginTop: "5px", width: "80%", fontSize: "12px" }}>
                {content}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Controls;
