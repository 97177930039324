import { keys } from "../../helpers/config";
import Request from "../../helpers/request";
import { buildUrl } from "../../helpers/urlManager";

class UserService {
  authorizationHeader = "";
  apiKey = "";
  env = "";

  constructor(env) {
    this.env = env;
    this.authorizationHeader = localStorage.getItem("idToken");
    this.apiKey = keys[env].apiKey;
  }

  fetchUserByUsername(username) {
    const url = buildUrl(
      { env: this.env, endpoint: `users` },
      { filter: `userId:eq_case_insensitive:${username}`, fields: "groups" }
    );

    return Request.doRequest({
      url,
      method: "GET",
      headers: {
        Authorization: this.authorizationHeader,
        "x-api-key": this.apiKey,
      },
    });
  }
}

export default new UserService("test");
